import React from 'react';

const Item = ({ PAGE_CLASS, COMPONENT_CLASS, data }) => {
  const { name, title, img, list } = data;
  return (
    <>
      <div className={`${PAGE_CLASS}__${COMPONENT_CLASS}__item`}>
        <div className="row align-items-stretch">
          <div className="col-6 d-flex flex-column align-items-center justify-content-between flex-grow-1">
            <div className={`${PAGE_CLASS}__${COMPONENT_CLASS}__img`}>
              <img src={img} alt={name} className="e-img e-img--cover" />
            </div>
            <div className="vstack gap-1 align-items-center flex-grow-0">
              <h6 className={`${PAGE_CLASS}__${COMPONENT_CLASS}__title`}>
                {name}
              </h6>
              <div className={`${PAGE_CLASS}__${COMPONENT_CLASS}__subtitle`}>
                {title}
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="vstack gap-2 h-100">
              <div className={`${PAGE_CLASS}__${COMPONENT_CLASS}__title`}>
                錄取學校
              </div>
              <ul className="vstack gap-2 c-list h-100">
                {list.map((v, i) => (
                  <li
                    key={i}
                    className={`${PAGE_CLASS}__${COMPONENT_CLASS}__li c-list__item`}
                  >
                    {v}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Item;
