import React from 'react';

const Title = ({ text }) => {
  return (
    <>
      <div className="c-title c-title--center c-title--activity">
        <h1 className="c-title__text">{text}</h1>
      </div>
    </>
  );
};

export default Title;
