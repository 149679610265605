import React from 'react';

function Slogan() {
  return (
    <>
      <div
        className="l-key-visual__slogan"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-offset="-500"
      >
        <div className="row gx-0 justify-content-center w-100">
          <div className="col-10 d-flex flex-column align-items-center">
            <h1
              className="l-key-visual__text"
              data-text="Learn AI, shaping the future."
            >
              Learn AI, shaping the future.
            </h1>
          </div>
        </div>
      </div>
    </>
  );
}

export default Slogan;
