import React from 'react';

const Footer = ({ LAYOUT_CLASS }) => {
  return (
    <>
      <section className={`${LAYOUT_CLASS}__footer`}>
        <div className={`${LAYOUT_CLASS}__footer__fg`} />
        <div className={`${LAYOUT_CLASS}__footer__bg`} />
      </section>
    </>
  );
};

export default Footer;
