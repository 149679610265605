import React from 'react';

import HeadingSection from 'components/SummerCamp/HeadingSection';

import blockImg from 'img/summer-camp/block.png';
import { CourseSection } from 'components/SummerCamp/Course';
import { FeatureSection } from 'components/SummerCamp/Feature';
import { TimeSection } from 'components/SummerCamp/Time';
import { QASection } from 'components/SummerCamp/QA';
import { CarouselSection } from 'components/SummerCamp/Carousel';

const SummerCamp = () => {
  const blockList = [
    {
      id: 1,
      img: blockImg,
    },
    {
      id: 2,
      img: blockImg,
    },
    {
      id: 3,
      img: blockImg,
    },
    {
      id: 4,
      img: blockImg,
    },
  ];

  const LAYOUT_CLASS = 'l-summer-camp';

  return (
    <>
      <HeadingSection LAYOUT_CLASS={LAYOUT_CLASS} />
      <CourseSection LAYOUT_CLASS={LAYOUT_CLASS} />
      <FeatureSection LAYOUT_CLASS={LAYOUT_CLASS} />
      <TimeSection LAYOUT_CLASS={LAYOUT_CLASS} />
      <QASection LAYOUT_CLASS={LAYOUT_CLASS} />
      <CarouselSection LAYOUT_CLASS={LAYOUT_CLASS} />
      {blockList.map((v) => {
        const { id, img } = v;
        return (
          <div key={id} className="l-summer-camp__block" data-block={`${id}`}>
            <img src={img} alt="block" className="e-img e-img--contain" />
          </div>
        );
      })}
    </>
  );
};

export default SummerCamp;
