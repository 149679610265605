import React, { useState } from 'react';

import CourseItem from 'components/CourseItem';

import { courseList } from 'data/course';

const Item = (props) => {
  const { data, active, setActive } = props;
  const { id, title, content, courseIds, btns } = data;

  const isActive = active === id;

  const handleCardClick = () => {
    if (active === id) {
      setActive(0);
    } else {
      setActive(id);
    }
  };

  return (
    <>
      <button
        type="button"
        className={`l-learning-path__list-card ${isActive ? 'active' : ''}`}
        onClick={handleCardClick}
      >
        <div className="l-learning-path__deco"></div>
        <div className="l-learning-path__heading">
          <h6 className="l-learning-path__title">{title}</h6>
          <p className="l-learning-path__subtitle">{content}</p>
          {!isActive && (
            <div className="l-learning-path__action e-btn e-btn--text e-btn--wmax e-btn--secondary">
              查看課程
            </div>
          )}
        </div>
        {isActive && (
          <div className="l-learning-path__content">
            <div className="row g-4">
              {courseIds.map((id) => {
                const matchCourse = courseList.find(
                  (course) => course.id === id
                );
                return <CourseItem key={id} data={matchCourse} col="6" />;
              })}
              {btns &&
                btns.map((v) => {
                  const { id } = v;
                  return <CourseItem key={id} data={v} col="6" />;
                })}
            </div>
          </div>
        )}
      </button>
    </>
  );
};

export default Item;
