import React from 'react';

import Title from '../Title';

import decoImg2 from 'img/summer-camp/deco-2.png';
import decoImg3 from 'img/summer-camp/deco-3.png';
import { featureList } from 'data/summer-camp';

const Content = ({ LAYOUT_CLASS }) => {
  const BREAKPOINT = 'lg';
  return (
    <>
      <section
        className={`${LAYOUT_CLASS}__section u-section position-relative`}
      >
        <Title text="ThousandAI的特色" deco="FEATURE" decoStyle="2" />
        <div className="row align-items-center justify-content-center g-5 ">
          {featureList.map((v) => (
            <div key={v.id} className={`col-6 col-${BREAKPOINT}-3`}>
              <div
                className={`${LAYOUT_CLASS}__feature__item vstack align-items-center gap-3`}
              >
                <div className={`${LAYOUT_CLASS}__feature__img`}>
                  <img
                    src={v.icon}
                    alt=""
                    className={`${LAYOUT_CLASS}__feature__icon`}
                  />
                </div>
                <h5 className={`${LAYOUT_CLASS}__subtitle`}>{v.title}</h5>
                <p className={`${LAYOUT_CLASS}__text text-center`}>
                  {v.content}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className={`${LAYOUT_CLASS}__deco`} data-deco="2">
          <img src={decoImg2} alt="" />
        </div>
        <div className={`${LAYOUT_CLASS}__deco`} data-deco="3">
          <img src={decoImg3} alt="" />
        </div>
      </section>
    </>
  );
};

export default Content;
