import courseImg1 from 'img/home/pic/category__apcs.jpg';
import courseImg2 from 'img/home/pic/category__python.jpg';
import courseImg3 from 'img/home/pic/category__unity.jpg';
import courseImg4 from 'img/home/pic/category__ai.jpg';

import outlineImg1 from 'img/course/pic/outline--apcs1.jpg';
import outlineImg2 from 'img/course/pic/outline--apcs2.jpg';
import outlineImg3 from 'img/course/pic/outline--apcs3.jpg';
import outlineImg4 from 'img/course/pic/outline--ai.jpg';
import outlineImg5 from 'img/course/pic/outline--python1.jpg';

export const skillList = [
  { id: 1, title: 'Python', link: 'https://www.python.org/' },
  { id: 2, title: 'Pycharm', link: 'https://www.python.org/' },
  { id: 3, title: 'Github', link: 'https://github.com/' },
  { id: 4, title: 'C', link: 'https://devdocs.io/c/' },
  { id: 5, title: 'C++', link: 'https://isocpp.org/' },
  { id: 6, title: 'Numpy', link: 'https://numpy.org/' },
  { id: 7, title: 'Pandas', link: 'https://pandas.pydata.org/' },
  { id: 8, title: 'Matplotlib', link: 'https://matplotlib.org/' },
  { id: 10, title: 'Zerojudge', link: 'https://zerojudge.tw/' },
  { id: 11, title: 'Pygame', link: 'https://www.pygame.org/news' },
  { id: 12, title: 'Code::Blocks', link: 'https://www.codeblocks.org/' },
  { id: 13, title: 'Leetcode', link: 'https://leetcode.com/' },
  { id: 14, title: 'Matrix' },
  { id: 15, title: 'Algorithm' },
  { id: 16, title: 'Tree' },
  { id: 17, title: 'BFS' },
  { id: 18, title: 'DFS' },
  { id: 19, title: 'Greedy' },
  { id: 20, title: 'Dynamic Programming' },
  { id: 21, title: 'Pytorch', link: 'https://pytorch.org/' },
  { id: 22, title: 'AI Model' },
  { id: 23, title: 'Midjourney' },
  { id: 24, title: 'ChatGPT' },
  {
    id: 25,
    title: 'Google Colab',
    link: 'https://colab.research.google.com/?hl=zh-tw',
  },
  { id: 26, title: 'HackerRank', link: 'https://www.hackerrank.com/' },
];

export const precaution = [
  {
    id: 'procedure',
    title: '報名流程',
    content: [
      '報名：填寫線上報名表，完成線上報名',
      '繳費：收到繳費資訊的電子郵件通知後，於三個工作天內完成繳費',
      '確認：我們將於對帳後寄發確認信函',
      '提醒：課程前一將寄發電子郵件的營前通知',
    ],
  },
  {
    id: 'preparation',
    title: '課前準備',
    content: [
      '新生報名前，必須先完成檢測',
      '電腦規格為 Windows 系統需 64 位元，Mac 系統需 OS11.0(Big Sur)以上作業系統',
    ],
  },
  {
    id: 'refund',
    title: '退費辦法',
    content: [
      '於開課日 30 天(含) 前提出，酌收手續費 200 元',
      '於開課日 8-30天 前提出，退回 95% 學費',
      '於開課日前 1-7天 提出，退回 90% 學費',
      '實際開課日起，且未達總課程時數之1/3提出，退回50%學費',
    ],
  },
  {
    id: 'others',
    title: '其他事項',
    content: [
      '為遵守個人資料保護法，課程期間所拍攝之照片/影像/聲音，僅做相關活動推廣，若不同意請主動告知，否則即視同同意提供',
      '為提供更完善的學習品質，CodingBar將保留課程內容及場地調整的權利',
      '逢颱風、地震、豪雨、疫情等不可控之因素，依當地政府規定停課與否，本單位恕不另行通知；若營隊期間停課，依停課時間比例，本單位無條件退還等比例學費。',
      '為維持良好學習效果，橘子蘋果兒童程式學苑保有營隊梯次關閉/合併之權力。',
      '若因課程調整，導致孩子無法順利參與課程，橘子蘋果將全額退款。',
      '若有任何疑問，歡迎透過 Line@ (@codingbar) 或來電 (02) 7717-9386 與我們聯絡。',
    ],
  },
];

const pythonPrices = [
  {
    id: 'classroom',
    total: 13000,
  },
  {
    id: 'online',
    total: 11000,
  },
  {
    id: 'digital',
    total: 9000,
  },
];

const apcs1Prices = [
  {
    id: 'classroom',
    total: 15000,
  },
  {
    id: 'online',
    total: 13000,
  },
  {
    id: 'digital',
    total: 11000,
  },
];

// 課程資訊 & 課程卡片 & 課程總覽 (順序 by order)
export const courseList = [
  // pycs
  {
    order: 1,
    id: 'pycs1',
    img: courseImg2,
    title: 'Python x AI',
    subtitle: '程式邏輯培養，資料科學分析',
    description: ``,
    introduction: `Python 是一門非常適合初學者的程式語言，因為它易於學習且具有很高的可讀性，且常常是最流行與實用的語言之一。這門課程適合完全零基礎的學員。前4堂課教導 Python 的基礎語法、培養程式邏輯、訓練解題技巧，使用知名解題網站 HackerRank 檢測學習成果。第5堂課開始偏向於實務應用，Pygame、資料科學、AI技術實作。課程共計 8 堂課（20 小時）和 6 次作業，使用 Discord 平台協助完成作業，本課程目標為學習完此課程能擁有基本的程式能力，並應用在實務上。`,
    age: '小五以上',
    priorKnowledge: '完全0基礎，有基本代數概念',
    including: [
      {
        id: 'homework',
        num: 6,
      },
      {
        id: 'mid-term',
        num: 0,
      },
      {
        id: 'final',
        num: 0,
      },
      {
        id: 'project',
        num: 0,
      },
    ],
    skills: ['Python', 'Pygame', 'Github', 'HackerRank', 'ChatGPT'],
    classes: [
      {
        id: '1',
        date: '2024/7/2-2024/7/12',
        time: '9:30-12:00',
        tutor: 'Thousand',
        type: 'both',
      },
      {
        id: '2',
        date: '2024/7/16-2024/7/26',
        time: '9:30-12:00',
        tutor: 'Thousand',
        type: 'both',
      },
      {
        id: '3',
        date: '2024/8/6-2024/8/16',
        time: '9:30-12:00',
        tutor: 'Thousand',
        type: 'both',
      },
      {
        id: '4',
        date: '2024/8/20-2024/8/29',
        time: '9:30-12:00',
        tutor: 'Thousand',
        type: 'both',
      },
    ],
    lengthPerClass: 2.5,
    totalClasses: 8,
    prices: pythonPrices,
    plans: [
      {
        id: 'A',
        title: '早鳥方案',
        subtitle: '折抵 $1000',
        content: '2024/6/20 前限時獨享，可與其他優惠併用！',
      },
      {
        id: 'B',
        title: '新生團報方案',
        subtitle: '折抵 $500',
        content: '新生2人以上同行，每人皆可享有，可與其他優惠併用！',
      },
      {
        id: 'C',
        title: '舊生續報方案',
        subtitle: '折抵 $1500',
        content: '感恩回饋價，不可與其他優惠併用！',
      },
    ],
    outline: outlineImg5,
    more: ['apcs1', 'apcs2', 'apcs3'],
    category: 'pycs',
    signup: 'https://www.surveycake.com/s/byNev',
    // schedule:
    //   'https://drive.google.com/drive/folders/1f5BYEi9VRMIP79EVAxzkGG2tc08drB7h',
  },
  // apcs
  {
    order: 2,
    id: 'apcs1',
    img: courseImg1,
    title: 'APCS 入門班',
    subtitle: 'C/C++ 程式設計，培養解題思維',
    description: ``,
    introduction:
      '最詳細的 C/C++ 語言課程，培養扎實的電腦科學知識以及建立縝密的解題思維。課程內容主要以 APCS 為主，透過 Zerojudge 刷題網站提升學員的程式能力。課程總共 8 堂課 (24小時)、6 次作業以及 2次期末檢定，同時提供 Discord 平台協助完成作業，與家長定期溝通學習狀況。本課程的目標為讓學員能獲得 APCS 觀念題 2-3 級分以及實作題 2 級分，建立良好的程式設計基礎和解題思維，為未來的學習打下堅實的基礎，且能豐富學習歷程檔案。',
    age: '國中生，高中生',
    priorKnowledge: '有些微基礎，需學過程式語言',
    including: [
      {
        id: 'homework',
        num: 6,
      },
      {
        id: 'mid-term',
        num: 0,
      },
      {
        id: 'final',
        num: 2,
      },
      {
        id: 'project',
        num: 0,
      },
    ],
    skills: ['C', 'C++', 'Zerojudge', 'Code::Blocks'],
    classes: [
      {
        id: '1',
        date: '2024/7/2-2024/7/12',
        time: '13:30-16:30',
        tutor: 'Thousand',
        type: 'both',
      },
      {
        id: '2',
        date: '2024/7/16-2024/7/26',
        time: '13:30-16:30',
        tutor: 'Thousand',
        type: 'both',
      },
      {
        id: '3',
        date: '2024/8/6-2024/8/16',
        time: '13:30-16:30',
        tutor: 'Thousand',
        type: 'both',
      },
      {
        id: '4',
        date: '2024/8/20-2024/8/29',
        time: '13:30-16:30',
        tutor: 'Thousand',
        type: 'both',
      },
    ],
    lengthPerClass: 2.5,
    totalClasses: 8,
    prices: apcs1Prices,
    plans: [
      {
        id: 'A',
        title: '早鳥方案',
        subtitle: '折抵 $1000',
        content: '2024/6/20 前限時獨享，可與其他優惠併用！',
      },
      {
        id: 'B',
        title: '新生團報方案',
        subtitle: '折抵 $500',
        content: '新生2人以上同行，每人皆可享有，可與其他優惠併用！',
      },
      {
        id: 'C',
        title: '舊生續報方案',
        subtitle: '折抵 $1500',
        content: '感恩回饋價，不可與其他優惠併用！',
      },
    ],
    outline: outlineImg1,
    more: ['apcs2', 'apcs3', 'pycs1'],
    category: 'apcs',
    signup: 'https://www.surveycake.com/s/Y8aVZ',
    // schedule:
    //   'https://drive.google.com/drive/folders/1J9nXdsZp_QnQ5pf-pa6rzNCsBVdg50Gm',
  },
  {
    order: 4,
    id: 'apcs2',
    img: courseImg1,
    title: 'APCS 進階班',
    subtitle: 'Matrix 解題能力，LeetCode刷題',
    description: ``,
    introduction: `本課程為進階課程，將學習解決 Vector 2D (Matrix) 問題的重要技巧，提升 Nested Loop 和 Matrix 的解題思維，將透過實作 APCS 歷屆試題來探索 Matrix 的相關技巧與應用。並在 Leetcode 中練習同類型的題目，提升熟練度與速度。課堂後半段會講解 Greedy Algorithm 並且實作 APCS 第 3 題，本課堂有 9 份作業，以及 3 次檢定，可大大提升程式能力，突破新手村，從 Junior 變成 Senior，目標為在 APCS 拿到觀念題 3-4級分，實作題 3 級分以上水準，並且可以參與其他國內的程式競賽。`,
    age: '國中生，高中生',
    priorKnowledge: '參與過 APCS 檢定 (觀念/實作 2/2 以上)',
    including: [
      {
        id: 'homework',
        num: 9,
      },
      {
        id: 'mid-term',
        num: 0,
      },
      {
        id: 'final',
        num: 0,
      },
      {
        id: 'project',
        num: 0,
      },
    ],
    skills: ['Matrix', 'Leetcode', 'Greedy'],
    classes: [
      {
        id: '1',
        date: '2023/8/6-2023/10/8',
        weekday: '日',
        time: '9:30-12:00',
        tutor: 'Thousand',
        type: 'both',
      },
      {
        id: '2',
        date: '2023/8/6-2023/10/8',
        weekday: '日',
        time: '13:30-16:00',
        tutor: 'Thousand',
        type: 'both',
      },
    ],
    lengthPerClass: 2.5,
    totalClasses: 10,
    prices: pythonPrices,
    plans: [
      {
        id: 'A',
        title: '早鳥方案',
        subtitle: '折抵 $500',
        content: '2023/7/17 前限時獨享，可與其他優惠併用！',
      },
      {
        id: 'B',
        title: '新生團報方案',
        subtitle: '折抵 $500',
        content: '新生2人以上同行，每人皆可享有！',
      },
      {
        id: 'C',
        title: '舊生續報方案',
        subtitle: '折抵 $1000',
        content: '感恩回饋價，感謝一路支持的學員～',
      },
    ],
    outline: outlineImg2,
    more: ['apcs1', 'apcs3', 'pycs1'],
    category: 'apcs',
    signup: 'https://www.surveycake.com/s/MPWdV',
  },
  {
    order: 5,
    id: 'apcs3',
    img: courseImg1,
    title: 'APCS 大師班',
    subtitle: '精通樹狀結構與演算法應用，勇闖程式競賽',
    description: ``,
    introduction: `本課程為大師課程。將教導學生如何分析和操作各種樹狀資料結構。 並學習運用 BFS、DFS、 Greedy、Dynamic Programming 等經典演算法來解決問題。本課程共12堂課，總計30小時，設有9份作業及3次檢定。課程目標在於協助學生在 APCS 檢定中獲得觀念題4分以上，實作題4分以上的優異成績，並且具備在程式競賽中獲得名次的能力。`,
    age: '國中生，高中生',
    priorKnowledge: '參與過 APCS 檢定 (觀念/實作 3/3 以上)',
    including: [
      {
        id: 'homework',
        num: 11,
      },
      {
        id: 'mid-term',
        num: 0,
      },
      {
        id: 'final',
        num: 0,
      },
      {
        id: 'project',
        num: 0,
      },
    ],
    skills: ['Tree', 'BFS', 'DFS', 'Greedy', 'Dynamic Programming'],
    classes: [
      {
        id: '1',
        date: '2023/8/5-2023/9/23',
        weekday: '三六',
        time: '19:15-21:45',
        tutor: 'Thousand',
        type: 'online',
      },
    ],
    lengthPerClass: 2.5,
    totalClasses: 12,
    prices: [
      {
        id: 'online',
        total: 15000,
      },
    ],
    plans: [
      {
        id: 'A',
        title: '早鳥方案',
        subtitle: '折抵 $500',
        content: '2023/7/31前限時獨享，可與其他優惠併用！',
      },
      {
        id: 'B',
        title: '新生團報方案',
        subtitle: '折抵 $500',
        content: '新生2人以上同行，每人皆可享有！',
      },
      {
        id: 'C',
        title: '舊生續報方案',
        subtitle: '折抵 $1000',
        content: '感恩回饋價，感謝一路支持的學員～',
      },
    ],
    outline: outlineImg3,
    more: ['apcs1', 'apcs2', 'pycs1'],
    category: 'apcs',
    signup: 'https://www.surveycake.com/s/m4vZM',
    schedule:
      'https://drive.google.com/drive/folders/1J9nXdsZp_QnQ5pf-pa6rzNCsBVdg50Gm',
  },
  // ai
  {
    order: 3,
    id: 'ai',
    img: courseImg4,
    title: 'AI DeepLearning',
    subtitle: 'From Zero to Hero, exploring the World of AI.',
    description: `數據分析工作涵蓋的範圍有: 資料前處理即呈現、資料庫串接、機器學習與深度學習三大方向，在初期的課程，Thousand AI 以 Python 作為基礎，帶領大家完成資料擷取、處理以及分析，最後以視覺化呈現資料的方式，完成三大方向中"資料前處理即呈現"這部分。`,
    introduction: `這是一門專為 AI 熱愛者而設的課程，從最基礎的知識開始，逐步深入介紹各種 AI 模型，包括語意分割、物件偵測、Transformer、AI 繪圖、ChatGpt 等最新且熱門的 AI 技術。本課程設有專案製作，讓學員深入了解 AI 的訓練過程和實際應用。不論你是高中生、大學生、碩士生或在職人士，都可以從中學習和精進自我，掌握最新的 AI 技術和未來發展趨勢。課程共有 8 堂，共計 24 小時，涵蓋最豐富的 AI 課程體驗。歡迎 AI 熱愛者們加入我們的行列，一起探索 AI 世界的奧秘！`,
    age: '高中生，大學生，碩士生，在職生',
    priorKnowledge: '會 Python 程式語言，對 AI 充滿熱忱',
    including: [
      {
        id: 'homework',
        num: 6,
      },
      {
        id: 'mid-term',
        num: 0,
      },
      {
        id: 'final',
        num: 0,
      },
      {
        id: 'project',
        num: 1,
      },
    ],
    skills: ['Pytorch', 'AI Model', 'Midjourney', 'ChatGPT', 'Github'],
    classes: [],
    lengthPerClass: 2.5,
    totalClasses: 8,
    prices: pythonPrices,
    plans: [],
    outline: outlineImg4,
    more: ['pycs1', 'apcs1', 'apcs2'],
    category: 'ai',
    signup: 'https://www.surveycake.com/s/v9D2w',
    // schedule:
    //   'https://drive.google.com/drive/folders/1VlOdslu8LYPQYF6wBSLRMIADivosixnS',
  },
];
