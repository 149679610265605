import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import Title from 'components/Title';

import { featureList } from 'data/home';

function Features() {
  return (
    <>
      <section
        className="l-home__features l-features u-home-section"
        id="features"
        data-section="4"
      >
        <div className="row gx-0 justify-content-center w-100">
          <div className="col-10 col-lg-8">
            <Title text="教學特色" align="center" />
            {featureList.map((item) => {
              const { id, title, list, img } = item;
              return (
                <div key={id} className="l-features__block" data-aos="fade-up">
                  <div className="c-title c-title--start">
                    <h5 className="c-title__text l-features__title">{title}</h5>
                  </div>
                  <div className="row g-4 gx-lg-5">
                    <div className="col-12 col-xl-6">
                      <ul className="c-list c-list--dot c-list--start ">
                        {list.map((item, i) => {
                          return (
                            <li key={i} className="c-list__item">
                              {item}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="col-12 col-xl-6">
                      <div className="l-features__img">
                        <img
                          className="e-img e-img--contain"
                          src={img}
                          alt="feature-slack"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}

export default Features;
