import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Banner from 'components/Banner';
import Opening from 'components/CourseIntro/Opening';
import SubjectAndTypes from 'components/CourseIntro/SubjectAndTypes';
import GradingSystem from 'components/CourseIntro/GradingSystem';
import AdoptingInstitutes from 'components/CourseIntro/AdoptingInstitutes';
import Introduction from 'components/CourseIntro/Introduction';

import { courseIntroList } from 'data/courseIntro';

const CourseIntro = () => {
  const { courseId } = useParams();
  const [data, setData] = useState({
    id: '',
    title: '',
    subtitle: '',
    opening: {},
    subjectsAndTypes: {},
    gradingSystem: {},
    adoptingInstitutes: {},
    introduction: {},
  });
  const {
    title,
    subtitle,
    opening,
    subjectsAndTypes,
    gradingSystem,
    adoptingInstitutes,
    introduction,
  } = data;

  const isFetchingData = data.id === '';

  useEffect(() => {
    const matchCourse = courseIntroList.find((v) => v.id === courseId);
    setData({ ...matchCourse });
  }, [courseId]);

  return (
    <>
      <Banner title={title} subtitle={subtitle} />
      {!isFetchingData && (
        <div className="l-course-intro">
          <div className="row gx-0 justify-content-center w-100">
            <div className="col-10 col-xl-8">
              <article className="u-page u-page--placeholder c-article">
                {opening && <Opening data={opening} />}
                {subjectsAndTypes && (
                  <SubjectAndTypes data={subjectsAndTypes} />
                )}
                {gradingSystem && <GradingSystem data={gradingSystem} />}
                {adoptingInstitutes && (
                  <AdoptingInstitutes data={adoptingInstitutes} />
                )}
                {introduction && <Introduction data={introduction} />}
              </article>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CourseIntro;
