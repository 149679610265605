import React from 'react';

import Title from 'components/Title';

import useWindowDimensions from 'hooks/useWindowDimensions';

function Quote() {
  const { height, width } = useWindowDimensions();
  return (
    <>
      <section className="l-home__about l-about u-home-section">
        <div className="row gx-0 justify-content-center w-100">
          <div className="col-10 col-lg-8 col-xl-8">
            <div className="c-quote">
              <blockquote className="c-quote__content">
                {/* <div className="c-quote__text">學習沒有捷徑，唯有不斷練習。</div>
          {width < 576 ? (
            <div className="c-quote__text">
              就讓全臺
              <h6 className="c-quote__em" data-text="最頂尖的專業師資">
                最頂尖的專業師資
              </h6>
              <h6 className="c-quote__em" data-text="最詳盡的課程規劃">
                最詳盡的課程規劃
              </h6>
              <h6 className="c-quote__em" data-text="最扎實的邏輯培養">
                最扎實的邏輯培養
              </h6>
              <h6 className="c-quote__em" data-text="最完善的程式訓練">
                最完善的程式訓練
              </h6>
            </div>
          ) : (
            <div className="c-quote__text">
              就讓全臺
              <h6
                className="c-quote__em"
                data-text="最頂尖的師資、最完善的課程規劃、最扎實的邏輯培養、最完善的程式訓練，"
              >
                最頂尖的師資、最詳盡的課程規劃、最扎實的邏輯培養、最完善的程式訓練，
              </h6>
            </div>
          )}
          <div className="c-quote__text">為孩子開啟程式學習的大門吧！</div> */}
                <div className="c-quote__text">
                  來自最頂尖的大學，熱愛 AI 研究，擁有專業的 Coding
                  能力與多年的教學經驗。最詳盡的課程規劃，最扎實的邏輯培養，最完善的程式訓練，
                  針對不同年齡層打造專屬課程，帶領孩子開發專案，豐富學習歷程檔案，攻向頂大。
                </div>
                <div className="c-quote__author">創辦人 Thousand</div>
                <div className="c-quote__deco" />
              </blockquote>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Quote;
