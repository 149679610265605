import tutorImg1 from 'img/home/avatar/faculty__thousand--2023.jpg';
import tutorImg2 from 'img/home/avatar/faculty__steven--2023.jpg';

import iconImg1 from 'img/winter-camp/icon/python.svg';
import iconImg2 from 'img/winter-camp/icon/apcs.svg';
import iconImg3 from 'img/winter-camp/icon/unity.svg';
import iconImg4 from 'img/winter-camp/icon/ai.svg';

const price = [
  '實體$12,500',
  '線上$10,500',
  '數位$8,500',
  '早鳥優惠折$500',
  '團報優惠折$500',
];

const href = `https://www.surveycake.com/s/v9D2w`;

export const courseList = [
  {
    id: 'python',
    index: 1,
    icon: iconImg1,
    title: 'Python x AI',
    content: `Python 是一門非常適合初學者的程式語言，可培養程式邏輯思維，為未來的程式打下扎實基礎。`,
    link: '/course/pycs1',
    list: [
      {
        id: '1',
        date: '2024/1/23－2024/2/2',
        weekday: '每週二三四五',
        time: '09：30-12：00',
        location: '台北市中山區民權西路20號2樓',
        price,
        tutor: {
          name: 'Thousand',
          avatar: tutorImg1,
        },
        href,
      },
    ],
  },
  {
    id: 'apcs',
    index: 2,
    icon: iconImg2,
    title: 'APCS 入門班',
    content: `最詳細的 C/C++ 語言課程，培養扎實的電腦科學知識以及建立縝密的解題思維，只需8堂課即可參加APCS檢定。`,
    link: '/course/apcs1',
    list: [
      {
        id: '1',
        date: '2024/1/23－2024/2/02',
        weekday: '每週二三四五',
        time: '13：30-16：00',
        location: '台北市中山區民權西路20號2樓',
        price,
        tutor: {
          name: 'Thousand',
          avatar: tutorImg1,
        },
        href,
      },
    ],
  },
  {
    id: 'unity',
    index: 3,
    icon: iconImg3,
    title: 'Unity 入門班',
    content: `探索遊戲開發的奧秘，學習如何打造遊戲，激發創意，掌握Unity技術，成為下一個遊戲創造者！`,
    link: '/',
    list: [
      {
        id: '1',
        date: '2024/1/23－2024/2/02',
        weekday: '每週二三四五',
        time: '13：30-16：00',
        location: '台北市中山區民權西路20號2樓',
        price,
        tutor: {
          name: 'Steven',
          avatar: tutorImg2,
        },
        href,
      },
    ],
  },
];

export const qaList = [
  {
    id: '1',
    title: '實體課、線上課、數位影音課有什麼區別？',
    content: `實體課特色
      1.老師較好掌握學生學習狀況
      2.課程中有任何問題可以隨時提問
      3.課堂中同學們可以互相學習探討
      4.皆附數位影音課程可以看
      
      線上課特色
      1.在家即可學習
      2.有問題可以線上即時提出並解答
      3.皆附數位影音課程可以看
      
      數位影音課程特色
      1.隨時隨地都可以學習
      2.無限時間、次數的觀看
      3.有問題時可以與老師約時間討論`,
  },
  {
    id: '2',
    title: '請問我實體課上到一半想轉成線上課可以嗎？',
    content: '可以，會按照比例退還場地費 250元/堂，手續費則需自行負擔。',
  },
  {
    id: '3',
    title: '是否有試聽課程？',
    content: '有試聽課程，需要負擔一堂課的費用，且不能夠享有任何優惠。',
  },
  {
    id: '4',
    title: '實體課需要自備電腦去上課嗎？',
    content: '由於資訊安全疑慮，怕個人資料外漏，所以必須要自備電腦來上課。',
  },
  {
    id: '5',
    title: '課程期間請假的話，會導致進度落後嗎？',
    content: '每堂課都會有數位檔案，可以盡快看影片補上進度，不會導致進度落後。',
  },
  {
    id: '6',
    title: '如果沒有任何程式經驗，哪個課程比較適合我？',
    content:
      '若年齡為高中並且有想要參與 APCS 檢定，則可以從 APCS 入門班直接開始。若年齡為國中，則建議先從 Python 入門班慢慢打好基礎。',
  },
];
