import React from 'react';
import Banner from 'components/Banner';
import { KeyVisual } from 'components/StudyAbroad/KeyVisual';
import { Heading } from 'components/StudyAbroad/Heading';
import { Feature } from 'components/StudyAbroad/Feature';
import { Pros } from 'components/StudyAbroad/Pros';
import { AdmissionList } from 'components/StudyAbroad/AdmissionList';

const StudyAbroad = () => {
  const PAGE_CLASS = 'l-study-abroad';

  return (
    <>
      <Banner title="國外大學推甄" subtitle="Study Abroad" />
      <KeyVisual PAGE_CLASS={PAGE_CLASS} />
      <Heading PAGE_CLASS={PAGE_CLASS} />
      <Feature PAGE_CLASS={PAGE_CLASS} />
      <Pros PAGE_CLASS={PAGE_CLASS} />
      <AdmissionList PAGE_CLASS={PAGE_CLASS} />
    </>
  );
};

export default StudyAbroad;
