import { createContext, useContext } from 'react';

export const CourseDataContext = createContext();

export function useCourseData() {
  return useContext(CourseDataContext);
}

export const OverviewDataContext = createContext();

export function useOverviewData() {
  return useContext(OverviewDataContext);
}

export const HeaderContext = createContext();

export function useHeader() {
  return useContext(HeaderContext);
}
