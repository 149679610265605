import React from 'react';

import Accordion from 'react-bootstrap/Accordion';

import Heading from 'components/Home/Feedbacks/Heading';

import { feedbackList } from 'data/home';

function MobileList() {
  return (
    <>
      <div className="l-feedback__accordion">
        <Accordion
          className="c-accordion c-accordion--header"
          defaultActiveKey="1"
        >
          {feedbackList.map((v) => {
            const { id, content } = v;
            return (
              <Accordion.Item
                key={id}
                eventKey={id}
                className="c-accordion__item"
              >
                <Accordion.Button className="c-accordion__toggle">
                  <div className="d-flex align-items-start">
                    <Heading data={v} />
                  </div>
                </Accordion.Button>
                <Accordion.Body className="c-accordion__body">
                  <div className="l-feedback__text">{content}</div>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </div>
    </>
  );
}

export default MobileList;
