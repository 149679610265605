import {
  LINE_URL,
  FACEBOOK_URL,
  INSTAGRAM_URL,
  SERVICE_EMAIL,
} from 'utils/config';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro';

const lineIconLayout = () => {
  return (
    <FontAwesomeIcon
      icon={brands('line')}
      className="e-icon e-icon--primary e-icon--right l-header__icon fa-fw"
    />
  );
};

const facebookIconLayout = () => {
  return (
    <FontAwesomeIcon
      icon={brands('facebook-square')}
      className="e-icon e-icon--primary e-icon--right l-header__icon fa-fw"
    />
  );
};

const instagramIconLayout = () => {
  return (
    <FontAwesomeIcon
      icon={brands('instagram-square')}
      className="e-icon e-icon--primary e-icon--right l-header__icon fa-fw"
    />
  );
};

const emailIconLayout = () => {
  return (
    <FontAwesomeIcon
      icon={solid('envelope')}
      className="e-icon e-icon--primary e-icon--right l-header__icon fa-fw"
    />
  );
};

// 導覽列
export const headerList = [
  {
    id: '1',
    title: 'AI課程',
    list: [
      {
        id: '1-3',
        title: 'Python x AI',
        link: '/course/pycs1',
        blank: false,
      },
    ],
  },
  {
    id: '2',
    title: 'APCS課程',
    list: [
      {
        id: '2-1',
        title: 'APCS介紹',
        link: '/course-intro/apcs',
        blank: false,
      },
      {
        id: '2-2',
        title: 'APCS入門班',
        link: '/course/apcs1',
        blank: false,
      },
      {
        id: '2-3',
        title: 'APCS進階班',
        link: '/course/apcs2',
        blank: false,
      },
      {
        id: '2-4',
        title: 'APCS大師班',
        link: '/course/apcs3',
        blank: false,
      },
    ],
  },
  // {
  //   id: '3',
  //   title: 'Unity遊戲',
  //   list: [
  //     {
  //       id: '3-1',
  //       title: 'Unity遊戲介紹',
  //       link: '/course-intro/unity',
  //       blank: false,
  //     },
  //     {
  //       id: '3-2',
  //       title: 'Unity遊戲課程',
  //       link: '/course/unity',
  //       blank: false,
  //     },
  //   ],
  // },
  // {
  //   id: '3',
  //   title: '暑期班資訊',
  //   link: '/summer-camp',
  //   blank: false,
  // },
  {
    id: '4',
    title: '暑期班資訊',
    link: '/summer-camp',
    blank: false,
  },
  // {
  //   id: '9',
  //   title: '國外大學推甄',
  //   link: '/study-abroad',
  //   blank: false,
  // },
  {
    id: '5',
    title: '學習地圖',
    link: '/learning-path',
    blank: false,
    // list: [
    //   {
    //     id: '5-1',
    //     title: '國內升學',
    //     link: '/course/pycs1001',
    //     blank: false,
    //   },
    //   {
    //     id: '5-2',
    //     title: '海外升學',
    //     link: '/course/pycs1001',
    //     blank: false,
    //   },
    // ],
  },
  {
    id: '6',
    title: '競賽培訓',
    link: '/competition-training',
    blank: false,
  },
  {
    id: '7',
    title: '學員回饋',
    link: '/student-feedback',
    blank: false,
  },
  {
    id: '8',
    title: '聯絡我們',
    list: [
      {
        id: '6-1',
        title: 'Line',
        link: LINE_URL,
        blank: true,
        icon: lineIconLayout(),
      },
      {
        id: '6-2',
        title: 'Facebook',
        link: FACEBOOK_URL,
        blank: true,
        icon: facebookIconLayout(),
      },
      {
        id: '6-3',
        title: 'Instagram',
        link: INSTAGRAM_URL,
        blank: true,
        icon: instagramIconLayout(),
      },
      {
        id: '6-4',
        title: 'Email',
        link: `mailto:${SERVICE_EMAIL}`,
        blank: true,
        icon: emailIconLayout(),
      },
    ],
  },
];
