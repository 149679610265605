import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import swiperImg1 from 'img/winter-camp/1.jpg';
import swiperImg2 from 'img/winter-camp/2.jpg';
import swiperImg3 from 'img/winter-camp/3.jpg';
import swiperImg4 from 'img/winter-camp/4.jpg';
import swiperImg5 from 'img/winter-camp/5.jpg';
import swiperImg6 from 'img/winter-camp/6.jpg';
import swiperImg7 from 'img/winter-camp/7.jpg';
import swiperImg8 from 'img/winter-camp/8.jpg';
import swiperImg9 from 'img/winter-camp/9.jpg';
import swiperImg10 from 'img/winter-camp/10.jpg';
import swiperImg11 from 'img/winter-camp/11.jpg';

const Pictures = ({ LAYOUT_CLASS }) => {
  const picList = [
    {
      id: 1,
      img: swiperImg1,
    },
    {
      id: 2,
      img: swiperImg2,
    },
    {
      id: 3,
      img: swiperImg3,
    },
    {
      id: 4,
      img: swiperImg4,
    },
    {
      id: 5,
      img: swiperImg5,
    },
    {
      id: 6,
      img: swiperImg6,
    },
    {
      id: 7,
      img: swiperImg7,
    },
    {
      id: 8,
      img: swiperImg8,
    },
    {
      id: 9,
      img: swiperImg9,
    },
    {
      id: 10,
      img: swiperImg10,
    },
    {
      id: 11,
      img: swiperImg11,
    },
  ];
  const breakpoints = {
    0: {
      slidesPerView: 1,
    },
    992: {
      slidesPerView: 3,
    },
  };
  return (
    <>
      <section className={`${LAYOUT_CLASS}__pictures`}>
        <Swiper
          breakpoints={breakpoints}
          slidesPerView={3}
          speed={1000}
          loop={true}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          navigation={{
            prevEl: `.${LAYOUT_CLASS}__swiper__action.prev`,
            nextEl: `.${LAYOUT_CLASS}__swiper__action.next`,
          }}
          modules={[Autoplay, Navigation]}
          className={`${LAYOUT_CLASS}__swiper`}
        >
          <h1
            className={`${LAYOUT_CLASS}__title ${LAYOUT_CLASS}__swiper__title`}
          >
            活動花絮
          </h1>
          {picList.map((v) => {
            const { id, title, img } = v;
            return (
              <SwiperSlide key={id} className={`${LAYOUT_CLASS}__swiper__item`}>
                <img src={img} alt={title} className="e-img e-img--cover" />
              </SwiperSlide>
            );
          })}
          <div className={`${LAYOUT_CLASS}__swiper__deco top`} />
          <div className={`${LAYOUT_CLASS}__swiper__deco bottom`} />
          <button className={`${LAYOUT_CLASS}__swiper__action prev`}>
            <FontAwesomeIcon icon={solid('angle-left')} className="fa-fw" />
          </button>
          <button className={`${LAYOUT_CLASS}__swiper__action next`}>
            <FontAwesomeIcon icon={solid('angle-right')} className="fa-fw" />
          </button>
          <div className={`${LAYOUT_CLASS}__swiper__line left`} />
          <div className={`${LAYOUT_CLASS}__swiper__line right`} />
        </Swiper>
      </section>
    </>
  );
};

export default Pictures;
