import useWindowDimensions from 'hooks/useWindowDimensions';
import React from 'react';

const Title = (props) => {
  const { text, deco, decoStyle } = props;
  const { width } = useWindowDimensions();
  return (
    <>
      <div className="l-summer-camp__title__bg" data-summer={decoStyle}>
        {deco.toUpperCase()}
        <h1 className="l-summer-camp__title__text text-nowrap">{text}</h1>
      </div>
    </>
  );
};

export default Title;
