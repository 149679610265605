import React from 'react';
import { Link } from 'react-router-dom';

function NotFound() {
  const letterList = [
    '糟',
    '糕',
    '，',
    '找',
    '不',
    '到',
    '此',
    '頁',
    '面',
    '！',
  ];
  return (
    <>
      <div className="l-not-found">
        <div className="l-not-found__content">
          <h1 className="l-not-found__title">404 Not Found</h1>
          <div className="position-relative">
            {letterList.map((item, i) => {
              return (
                <span
                  key={i}
                  className="l-not-found__waviy"
                  style={{ '--order__letter': i }}
                >
                  {item}
                </span>
              );
            })}
          </div>
          <Link to="/" className="e-btn e-btn--primary l-not-found__action">
            回首頁
          </Link>
        </div>
      </div>
    </>
  );
}

export default NotFound;
