import Title from 'components/Title';
import React from 'react';
import { Link } from 'react-router-dom';

const Content = ({ PAGE_CLASS }) => {
  const COMPONENT_CLASS = 'heading';
  return (
    <>
      <div
        className={`${PAGE_CLASS}__${COMPONENT_CLASS} ${PAGE_CLASS}__section`}
        data-section="1"
      >
        <div className="row justify-content-center g-0">
          <div className="col-8">
            <div className="vstack gap-3 text-center">
              <Title text="什麼是國外推甄" align="center" />
              <p className={`${PAGE_CLASS}__text`}>
                我是國外推甄的基本簡介我是國外推甄的基本簡介我是國外推甄的基本簡介我是國外推甄的基本簡介我是國外推甄的基本簡介我是國外推甄的基本簡介我是國外推甄的基本簡介我是國外推甄的基本簡介我是國外推甄的基本簡介我是國外推甄的基本簡介我是國外推甄的基本簡介我是國外推甄的基本簡介我是國外推甄的基本簡介我是國外推甄的基本簡介我是國外推甄的基本簡介我是國外推甄的基本簡介我是國外推甄的基本簡介我是國外推甄的基本簡介
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
