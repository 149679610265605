import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import Title from 'components/Title';

import { useCourseData } from 'utils/context';
import { NOTION_URL } from 'utils/config';

function Info() {
  const { courseData } = useCourseData();
  const { classes, lengthPerClass, totalClasses, prices, schedule } =
    courseData;

  const totalLength = lengthPerClass * totalClasses;
  const isEmptyClasses = classes.length === 0;

  const handleTagLayout = (type) => {
    switch (type) {
      case 'online':
        return (
          <div className="e-tag e-tag--right" data-tag="線上">
            線上
          </div>
        );
      case 'classroom':
        return (
          <div className="e-tag e-tag--right" data-tag="實體">
            實體
          </div>
        );
      case 'digital':
        return (
          <div className="e-tag e-tag--right" data-tag="數位">
            數位
          </div>
        );
      case 'both':
      default:
        return (
          <div className="d-flex align-items-center flex-wrap">
            <div className="e-tag e-tag--right" data-tag="線上">
              線上
            </div>
            <div className="e-tag e-tag--right" data-tag="實體">
              實體
            </div>
            <div className="e-tag e-tag--right" data-tag="數位">
              數位
            </div>
          </div>
        );
    }
  };

  return (
    <>
      <section className="l-course__info u-section" id="info">
        <Title text="開課資訊" align="start" />
        <ul className="c-list c-list--item">
          <li className="c-list__item d-flex flex-column flex-xl-row">
            <div className="c-list__title text-nowrap">開課班次</div>
            <ul className="c-list c-list--tag">
              <li className="c-list__item">
                <a
                  href={schedule}
                  className="e-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  查看所有開課班次
                </a>
              </li>
              {!isEmptyClasses ? (
                classes.map((item) => {
                  const { id, date, weekday, time, tutor, type } = item;
                  return (
                    <li
                      key={id}
                      className="c-list__item mb-2 d-flex flex-column flex-sm-row flex-md-column flex-lg-row align-items-sm-center align-items-md-start align-items-lg-center flex-wrap"
                    >
                      <div className="c-list__seg">{date}</div>
                      <div className="d-flex align-items-center flex-wrap">
                        <div className="c-list__seg c-list__seg--stress">
                          週{weekday}
                        </div>
                        <div className="c-list__seg">{time}</div>
                        <div className="c-list__seg c-list__seg--stress">
                          {tutor}
                        </div>
                        {handleTagLayout(type)}
                      </div>
                    </li>
                  );
                })
              ) : (
                <li className="c-list__item c-list__item--text">
                  目前沒有開課班次，籌備中♪
                </li>
              )}
            </ul>
          </li>
          <li className="c-list__item d-flex flex-column flex-xl-row">
            <div className="c-list__title text-nowrap">上課地點</div>
            <ul className="c-list c-list--tag">
              <li className="c-list__item d-flex align-items-center">
                <div className="e-tag e-tag--right" data-tag="實體">
                  實體
                </div>
                <a
                  href="https://thehapp.com/"
                  className="e-link e-link--left"
                  target="_blank"
                  rel="noreferrer"
                >
                  小樹屋
                </a>
              </li>
              <li className="c-list__item d-flex align-items-center">
                <div className="e-tag e-tag--right" data-tag="線上">
                  線上
                </div>
                <a
                  href="https://meet.google.com/"
                  className="e-link e-link--left"
                  target="_blank"
                  rel="noreferrer"
                >
                  Google Meet
                </a>
              </li>
            </ul>
          </li>
          <li className="c-list__item">
            <strong className="c-list__title">授課時數</strong>
            {lengthPerClass}hr/堂，總共{totalClasses}堂 ({totalLength}hrs)
          </li>
          <li className="c-list__item">
            <strong className="c-list__title">上課人數</strong>
            4-10人/班
          </li>
          <li className="c-list__item d-flex flex-column flex-xl-row">
            <strong className="c-list__title">課程原價</strong>
            <ul className="c-list c-list--tag">
              {prices.map((v) => {
                const { id, total } = v;
                let title;
                switch (id) {
                  case 'classroom':
                    title = '實體';
                    break;
                  case 'digital':
                    title = '數位';
                    break;
                  case 'online':
                  default:
                    title = '線上';
                    break;
                }
                return (
                  <li key={id} className="c-list__item">
                    <div className="e-tag e-tag--right" data-tag={title}>
                      {title}
                    </div>
                    {total}元/人
                  </li>
                );
              })}
            </ul>
          </li>
          <li>
            <FontAwesomeIcon
              icon={solid('circle-exclamation')}
              className="c-list__icon c-list__icon--highlight fa-fw"
            />
            配合使用視訊軟體
            <a
              href="https://meet.google.com/"
              className="e-link e-link--both"
              target="_blank"
              rel="noreferrer"
            >
              Google Meet
            </a>
            上課
          </li>
          <li>
            <FontAwesomeIcon
              icon={solid('circle-exclamation')}
              className="c-list__icon c-list__icon--highlight fa-fw"
            />
            使用團隊協作平台
            <a
              href="https://slack.com/intl/zh-tw/"
              className="e-link e-link--both"
              target="_blank"
              rel="noreferrer"
            >
              Slack
            </a>
            溝通及合作
          </li>
        </ul>
      </section>
    </>
  );
}

export default Info;
