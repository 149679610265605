import React, { useState, useEffect } from 'react';

const Filter = (props) => {
  const { active, setActive } = props;
  const categoryList = [
    {
      id: 'all',
      title: '全部',
    },
    {
      id: 'course',
      title: '課程',
    },
    {
      id: 'competition',
      title: '競賽',
    },
  ];

  const handleCategoryChange = (e) =>
    setActive({ ...active, category: e.target.value });

  return (
    <>
      <div className="l-latest-news__select c-form__select">
        <div className="l-latest-news__select-wrapper c-form__select-wrapper">
          <select
            className="l-latest-news__select-input c-form__select-input"
            name="type"
            value={active}
            onChange={handleCategoryChange}
          >
            {categoryList.map((v) => {
              const { id, title } = v;
              return (
                <option key={id} value={id}>
                  {title}活動
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </>
  );
};

export default Filter;
