import React, { useState } from 'react';

import Title from 'components/Title';
import List from 'components/Home/Courses/List';

function Courses() {
  return (
    <>
      <section className="l-home__course u-home-section" data-section="2">
        <div className="row gx-0 justify-content-center w-100">
          <div className="col-7 col-sm-9 col-xl-10">
            <Title text="課程總覽" align="center" />
            <List />
          </div>
        </div>
      </section>
    </>
  );
}

export default Courses;
