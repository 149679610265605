import React from 'react';

import { Link } from 'react-router-dom';

const CourseItem = (props) => {
  const { data, col } = props;
  const { id, img, title, introduction, link } = data;
  return (
    <>
      <div className={`col-12 col-md-6 col-xl-${col}`}>
        <Link
          to={link ? link : `/course/${id}`}
          className="w-100 h-100"
          title={introduction}
        >
          <div className="c-card c-card--link l-category__card">
            <div className="c-card__img l-category__img">
              <img className="e-img e-img--cover" src={img} alt="course-img" />
              <h6 className="c-card__title l-category__title">{title}</h6>
            </div>
            <div className="c-card__content l-category__content">
              <p className="c-card__text l-category__text">{introduction}</p>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default CourseItem;
