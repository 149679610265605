import React from 'react';
import { Link } from 'react-router-dom';

const Content = ({ PAGE_CLASS }) => {
  const COMPONENT_CLASS = 'key-visual';
  return (
    <>
      <div className={`${PAGE_CLASS}__${COMPONENT_CLASS}`}>
        <div className="row justify-content-center">
          <div className="col-8">
            <div
              className={`${PAGE_CLASS}__${COMPONENT_CLASS}__content vstack gap-5 text-center`}
            >
              <h1 className={`${PAGE_CLASS}__${COMPONENT_CLASS}__title`}>
                我是國外推甄的標題
              </h1>
              <p className={`${PAGE_CLASS}__${COMPONENT_CLASS}__subtitle`}>
                我是國外推甄的簡短說明我是國外推甄的簡短說明我是國外推甄的簡短說明我是國外推甄的簡短說明我是國外推甄的簡短說明
              </p>
              <Link
                to="/"
                className="e-btn e-btn--outline e-btn--wmax e-btn--light e-btn--text align-self-center"
              >
                聯絡我們
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
