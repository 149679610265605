import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

import 'swiper/css/navigation';

import Item from 'components/Home/Courses/Item';

import { courseList } from 'data/course';

function List() {
  const PAGE_CLASS = 'l-courses__list';
  const navigation = {
    nextEl: `.${PAGE_CLASS}__swiper__action.next`,
    prevEl: `.${PAGE_CLASS}__swiper__action.prev`,
  };
  const breakpoints = {
    0: {
      slidesPerView: 1,
      spaceBetween: 30,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
    1200: {
      slidesPerView: 3,
      spaceBetween: 24,
    },
    1920: {
      slidesPerView: 4,
      spaceBetween: 24,
    },
  };
  return (
    <>
      <div className={`${PAGE_CLASS} position-relative`}>
        <Swiper
          speed={1000}
          navigation={navigation}
          breakpoints={breakpoints}
          modules={[Navigation]}
          className={`c-swiper ${PAGE_CLASS}__swiper__action`}
        >
          {courseList
            .sort((a, b) => a.order - b.order)
            .map((v) => {
              const { id } = v;
              return (
                <SwiperSlide key={id} className="d-flex align-items-stretch">
                  <Item data={v} />
                </SwiperSlide>
              );
            })}
        </Swiper>
        <button
          type="button"
          className={`c-swiper__action prev swiper-button-prev ${PAGE_CLASS}__swiper__action`}
        ></button>
        <button
          type="button"
          className={`c-swiper__action next swiper-button-next ${PAGE_CLASS}__swiper__action`}
        ></button>
      </div>
    </>
  );
}

export default List;
