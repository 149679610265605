import React, { useState } from 'react';

import Title from 'components/Title';
import Item from 'components/LearningPath/Item';

import pathImg from 'img/learning-path/path--horizontal.jpg';

import { pathList } from 'data/learningPath';

const LearningPath = () => {
  const [active, setActive] = useState(0);
  return (
    <>
      <article className="l-learning-path">
        <section className="l-learning-path__section u-section c-article__section">
          <Title text="課程進路" align="start" />
          <p className="c-article__paragraph">
            近年來，資訊科技產業快速發展，程式設計已成為許多年輕人關注的職業之一。對於初學者而言，Python
            是一個容易上手且功能強大的程式語言，適合作為入門課程的學習工具。
          </p>
          <p className="c-article__paragraph">
            Python基礎課程結束後，可以根據個人興趣及發展方向選擇不同的進階課程。解題班是一個非常受歡迎的選擇，透過深入淺出的方式，引導學員逐步掌握複雜程式設計及演算法應用，包括APCS系列課程、競賽解題班等。
          </p>
          <p className="c-article__paragraph">
            實務專案是另一個極具吸引力的進階課程選項，它提供了最新的 AI
            專案開發技術，由淺入深地引導學員學習 AI 模型的建立過程，從零開始成為
            AI
            專家。這門課程對於高中生而言，可以成為學習歷程中的一部分；對於大學生及碩士生而言，更可以為未來的實驗和研究提供有力的幫助。
          </p>
          <p className="c-article__paragraph">
            透過適當的升學規劃，學員可以在自己的程式設計道路上取得更多的成就。無論是選擇解題班還是實務專案，都可以為未來的學習及職業發展打下堅實的基礎，並開啟更多的職業發展機會。
          </p>
          <div className="l-learning-path__img"></div>
        </section>
        <section className="l-learning-path__section u-section c-article__section">
          {pathList.map((v) => {
            const { id } = v;
            return (
              <Item key={id} data={v} active={active} setActive={setActive} />
            );
          })}
        </section>
      </article>
    </>
  );
};

export default LearningPath;
