import React, { useEffect } from 'react';

import Dropdown from 'react-bootstrap/Dropdown';

import HeaderItem from 'components/Header/HeaderItem';

function HeaderDropdown(props) {
  const { item, selected, setSelected } = props;
  const { id, title, list } = item;

  const isSelected = selected === id;

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
      className={`dropdown-toggle c-dropdown__toggle ${
        isSelected ? 'active' : ''
      }`}
    >
      {children}
    </button>
  ));

  useEffect(() => {
    document.addEventListener('click', (e) => {
      if (e.currentTarget === document && selected !== '') {
        setSelected('');
      }
    });
  });

  return (
    <>
      <Dropdown
        className="c-dropdown"
        show={isSelected}
        onMouseEnter={(e) => {
          e.stopPropagation();
          if (isSelected) {
            setSelected('');
          } else {
            setSelected(id);
          }
        }}
        onMouseLeave={() => setSelected('')}
      >
        <Dropdown.Toggle as={CustomToggle}>{title}</Dropdown.Toggle>
        <Dropdown.Menu as="ul" className="dropdown-menu c-dropdown__menu">
          {list.map((item) => {
            const { id } = item;
            return <HeaderItem key={id} layout="dropdown" item={item} />;
          })}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export default HeaderDropdown;
