import React, { useRef, useState } from 'react';

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import swiperImg1 from 'img/winter-camp/1.jpg';
import swiperImg2 from 'img/winter-camp/2.jpg';
import swiperImg3 from 'img/winter-camp/3.jpg';
import swiperImg4 from 'img/winter-camp/4.jpg';
import swiperImg5 from 'img/winter-camp/5.jpg';
import swiperImg6 from 'img/winter-camp/6.jpg';
import swiperImg7 from 'img/winter-camp/7.jpg';
import swiperImg8 from 'img/winter-camp/8.jpg';
import swiperImg9 from 'img/winter-camp/9.jpg';
import swiperImg10 from 'img/winter-camp/10.jpg';
import swiperImg11 from 'img/winter-camp/11.jpg';
import Title from '../Title';
import useWindowDimensions from 'hooks/useWindowDimensions';

const Main = ({ LAYOUT_CLASS }) => {
  const [activeSlide, setActiveSlide] = useState(1);
  const { width } = useWindowDimensions();
  const isMobile = width && width < 576;

  const picList = [
    {
      id: 1,
      img: swiperImg1,
    },
    {
      id: 2,
      img: swiperImg2,
    },
    {
      id: 3,
      img: swiperImg3,
    },
    {
      id: 4,
      img: swiperImg4,
    },
    {
      id: 5,
      img: swiperImg5,
    },
    {
      id: 6,
      img: swiperImg6,
    },
    {
      id: 7,
      img: swiperImg7,
    },
    {
      id: 8,
      img: swiperImg8,
    },
    {
      id: 9,
      img: swiperImg9,
    },
    {
      id: 10,
      img: swiperImg10,
    },
    {
      id: 11,
      img: swiperImg11,
    },
  ];

  const breakpoints = {
    0: {
      slidesPerView: 1,
    },
    576: {
      slidesPerView: 3,
    },
  };

  const handleSlideChange = (swiper) => {
    setActiveSlide(swiper.activeIndex + 1);
  };

  const handleClassName = (i) => {
    if (i === activeSlide) {
      return 'active';
    } else if (i === activeSlide - 1) {
      return 'prev';
    } else if (i === activeSlide + 1) {
      return 'next';
    } else {
      return '';
    }
  };
  return (
    <>
      <section className={`${LAYOUT_CLASS}__carousel position-relative`}>
        <Title text="活動花絮" deco="PICTURE" decoStyle="2" />
        <Swiper
          breakpoints={breakpoints}
          speed={1000}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          navigation={{
            prevEl: `.${LAYOUT_CLASS}__carousel__action.prev`,
            nextEl: `.${LAYOUT_CLASS}__carousel__action.next`,
          }}
          modules={[Autoplay, Navigation]}
          className={`${LAYOUT_CLASS}__${
            !isMobile ? 'carousel__swiper' : 'swiper'
          }`}
          onSlideChange={handleSlideChange}
        >
          {picList.map((v, i) => {
            const { id, title, img } = v;
            return (
              <SwiperSlide
                key={id}
                className={
                  !isMobile
                    ? `${LAYOUT_CLASS}__carousel__item ${handleClassName(i)}`
                    : `${LAYOUT_CLASS}__swiper__item`
                }
              >
                <img
                  src={img}
                  alt={title}
                  className={`e-img e-img--cover ${
                    !isMobile
                      ? `${LAYOUT_CLASS}__carousel__img ${handleClassName(i)}`
                      : ''
                  } `}
                />
              </SwiperSlide>
            );
          })}
          <button className={`${LAYOUT_CLASS}__carousel__action prev`}>
            <FontAwesomeIcon
              icon={solid('angle-left')}
              className="fa-fw e-icon--light"
            />
          </button>
          <button className={`${LAYOUT_CLASS}__carousel__action next`}>
            <FontAwesomeIcon
              icon={solid('angle-right')}
              className="fa-fw e-icon--light"
            />
          </button>
        </Swiper>
      </section>
    </>
  );
};

export default Main;
