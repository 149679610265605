import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Navbar from 'react-bootstrap/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import logo from 'img/common/logo-text--course.svg';
import MobileOffcanvas from 'components/Header/MobileOffcanvas';

import { useHeader } from 'utils/context';

function Header() {
  const { pathname } = useLocation();
  const { showCanvas, setShowCanvas } = useHeader();
  useEffect(() => {
    window.scrollTo(0, 0);
    setShowCanvas(false);
  }, [pathname]);

  const toggleLayout = () => {
    if (!showCanvas) {
      return (
        <FontAwesomeIcon
          icon={solid('bars')}
          className="l-header__icon e-icon e-icon--secondary fa-fw"
        />
      );
    } else {
      return (
        <FontAwesomeIcon
          icon={solid('xmark')}
          className="l-header__icon e-icon e-icon--secondary fa-fw"
        />
      );
    }
  };

  return (
    <>
      <Navbar expand="xl" className="l-header">
        <div className="row gx-0 justify-content-center w-100 h-100">
          <div className="col-11 col-xl-10 d-flex justify-content-between align-items-center">
            <div className="l-header__logo">
              <Link to="/">
                <img className="e-img e-img--contain" src={logo} alt="logo" />
              </Link>
            </div>
            <Navbar.Toggle
              aria-controls="navOffcanvas"
              className="l-header__toggle"
              onClick={() => setShowCanvas(!showCanvas)}
              children={toggleLayout()}
            />
            <Navbar.Offcanvas
              id="navOffcanvas"
              aria-labelledby="navOffcanvas"
              placement="end"
              backdropClassName="l-header__backdrop"
              className="l-header__offcanvas"
              show={showCanvas ? 1 : 0}
              onHide={() => setShowCanvas(false)}
              scroll="true"
            >
              <MobileOffcanvas />
            </Navbar.Offcanvas>
          </div>
        </div>
      </Navbar>
    </>
  );
}

export default Header;
