import React from 'react';
import { Link } from 'react-router-dom';

import { useHeader } from 'utils/context';

function HeaderItem(props) {
  const { layout, linkLayout, item } = props;
  const { id, title, link, blank, icon } = item;
  const { setShowCanvas } = useHeader();

  const handleItemClass = () => {
    if (layout === 'dropdown') {
      return 'c-dropdown__item';
    } else if (layout === 'accordion') {
      return 'c-list__item l-header__item c-header-accordion__item';
    } else {
      return 'l-header__item';
    }
  };

  const handleLinkClass = () => {
    if (layout === 'dropdown') {
      return 'c-dropdown__link';
    } else if (layout === 'accordion') {
      if (linkLayout) {
        return 'l-header__link c-header-accordion__link';
      } else {
        return 'l-header__link c-header-accordion__link c-header-accordion__link--accordion';
      }
    } else {
      return 'l-header__link';
    }
  };

  const handleCanvas = () => {
    setShowCanvas();
  };

  return (
    <>
      <li key={id} className={`${handleItemClass()}`}>
        {blank ? (
          <a
            href={link}
            className={`${handleLinkClass()}`}
            target="_blank"
            rel="noreferrer"
            onClick={handleCanvas}
          >
            {icon && icon}
            {title}
          </a>
        ) : (
          <Link
            to={link}
            className={`${handleLinkClass()}`}
            onClick={handleCanvas}
          >
            {icon && icon}
            {title}
          </Link>
        )}
      </li>
    </>
  );
}

export default HeaderItem;
