import avatarImg1 from 'img/student-feedback/avatar__ryan.jpg';
import avatarImg6 from 'img/student-feedback/avatar__Hung.jpg';
import avatarImg2 from 'img/home/avatar/feedback__frank.jpg';
import avatarImg3 from 'img/student-feedback/avatar__alan.jpg';
import avatarImg4 from 'img/student-feedback/avatar__peggy.jpg';
import avatarImg5 from 'img/student-feedback/avatar__sung.jpg';

import transcriptImg1 from 'img/student-feedback/transcript__ryan.jpg';
import thumbnailLeft1 from 'img/student-feedback/thumbnail__ryan--left.png';
import thumbnailRight1 from 'img/student-feedback/thumbnail__ryan--right.png';

import transcriptImg6 from 'img/student-feedback/transcript__Hung.jpg';
import thumbnailLeft6 from 'img/student-feedback/thumbnail__Hung--left.png';
import thumbnailRight6 from 'img/student-feedback/thumbnail__Hung--right.png';

import transcriptImg2 from 'img/student-feedback/transcript__frank.jpg';
import thumbnailLeft2 from 'img/student-feedback/thumbnail__frank--left.png';
import thumbnailRight2 from 'img/student-feedback/thumbnail__frank--right.png';

import transcriptImg3 from 'img/student-feedback/transcript__alan.jpg';
import thumbnailLeft3 from 'img/student-feedback/thumbnail__alan--left.png';
import thumbnailRight3 from 'img/student-feedback/thumbnail__alan--right.png';

import transcriptImg4 from 'img/student-feedback/transcript__peggy.jpg';
import thumbnailLeft4 from 'img/student-feedback/thumbnail__peggy--left.png';
import thumbnailRight4 from 'img/student-feedback/thumbnail__peggy--right.png';

import transcriptImg5 from 'img/student-feedback/transcript__sung.jpg';
import thumbnailLeft5 from 'img/student-feedback/thumbnail__sung--left.png';
import thumbnailRight5 from 'img/student-feedback/thumbnail__sung--right.png';

export const marqueeList = [
  {
    id: '1',
    title: '恭喜xxx同學在10月APCS檢定中獲得觀念題4分、實作題4分的佳績！',
    date: '2023.10.21',
  },
  {
    id: '2',
    title: '恭喜xxx同學在10月APCS檢定中獲得觀念題4分、實作題4分的佳績！',
    date: '2023.10.22',
  },
  {
    id: '3',
    title: '恭喜xxx同學在10月APCS檢定中獲得觀念題4分、實作題4分的佳績！',
    date: '2023.10.23',
  },
  {
    id: '4',
    title: '恭喜xxx同學在10月APCS檢定中獲得觀念題4分、實作題4分的佳績！',
    date: '2023.10.24',
  },
  {
    id: '5',
    title: '恭喜xxx同學在10月APCS檢定中獲得觀念題4分、實作題4分的佳績！',
    date: '2023.10.25',
  },
];

export const studentList = [
  {
    id: 'ryan',
    name: 'Ryan Chang',
    title: '台北市私立薇閣中學 國二',
    courses: ['APCS 入門班', 'APCS 進階班'],
    score: {
      before: {
        concept: 60,
        practice: 160,
      },
    },
    content:
      '很開心在這次考試中拿到不錯的分數, 而在這背後缺少不了自己的努力和老師的耐心指導.我從零級分到到三級分學習路程中, 雖然一開始不知道學程是可以做甚麼, 但透過一次次的實體課和同儕競爭中發現了學程式的動力和樂趣, 而我覺得老師在檢測前作業的統整也功不可沒, 不僅幫助很大也能即時知道自己的問題, 現在也持續的往五級分前進, 繼續挖掘屬於我的程式之路.',
    transcript: transcriptImg1,
    thumbnail: {
      left: thumbnailLeft1,
      right: thumbnailRight1,
    },
    avatar: avatarImg1,
  },
  {
    id: 'HUng',
    name: 'Hung',
    title: '台北市立南門國中 國一',
    courses: ['APCS 進階班', 'APCS 大師班'],
    score: {
      before: {
        concept: 76,
        practice: 300,
      },
    },
    content: `從小學三年級開始學程式後，我就對程式產生了濃厚的興趣。只要跟電腦下好指令就會自動執行，覺得很有趣。到了六年級，我開始學習C++。起初對於演算法不太熟練，但遇到了老師之後，開始有系統地學習演算法，這樣讓我更精進程式設計能力。`,
    transcript: transcriptImg6,
    thumbnail: {
      left: thumbnailLeft6,
      right: thumbnailRight6,
    },
    avatar: avatarImg6,
  },
  {
    id: 'frank',
    name: 'Frank Chiou',
    title: '台北市私立薇閣中學 國二',
    courses: ['APCS 入門班', 'APCS 進階班'],
    score: {
      before: {
        concept: 64,
        practice: 160,
      },
    },
    content: `學習程式語言是一項具有挑戰性、需要毅力的技能，一個Bug可能就需要花費長久的時間去修正，這也是老師厲害的地方，在每堂課中都可以用最快的速度去找出錯誤和解決方法。老師的課程中偶爾也會穿插一些較簡單有趣的部份，每次上課都不覺得枯燥乏味。同時，每一堂課都有錄影上傳，配上講義來看即便是錯過了一堂課也能補上，有疑問則在協作平台上提出便能得到答覆。老師總是可以以一個專精者的角度去解釋得很完善、簡單易懂。而每次需要他幫忙時，也是不辭辛勞的為每個學生做到最好、做我們最好的榜樣。

    我最驚嘆於老師對於各個程式語言和相關應用工具的精熟，不只限於我所學的C++，甚至是python、javascript老師都能掌握那些不同的技巧和格式，讓我和各路同學都能有所長進，也能拿下值得自傲的成績! 從兩年前開始至今也從完全的初學者進階到如今的程度，也見識許多的才華被發掘，加入課程絕不會後悔的! 不論是零基礎還是在追求專精，相信老師開設的課程正是你的理想選擇，傳授的不局限於比賽，而是真正遇到任何題目都能應對的思考方式和技巧。`,
    transcript: transcriptImg2,
    thumbnail: {
      left: thumbnailLeft2,
      right: thumbnailRight2,
    },
    avatar: avatarImg2,
  },
  {
    id: 'alan',
    name: 'Alan',
    title: '明倫高中 高二',
    courses: ['APCS 入門班', 'APCS 進階班', 'APCS 大師班'],
    score: {
      before: {
        concept: 68,
        practice: 200,
      },
    },
    content:
      '上課的時候還蠻有趣的，上的東西難度頗難但是不會說聽不懂（寫不寫得出來是另外一回事），老師人很好會慢慢解釋。一開始線上上課的時候會不小心睡著或者分心，後來去實體課之後有比較改善而且可以直接問，希望下次可以考好一點，我會多練👍',
    transcript: transcriptImg3,
    thumbnail: {
      left: thumbnailLeft3,
      right: thumbnailRight3,
    },
    avatar: avatarImg3,
  },
  {
    id: 'peggy',
    name: 'peggy',
    title: '北一女中 高二',
    courses: ['APCS 進階班', 'APCS 大師班'],
    score: {
      before: {
        concept: 76,
        practice: 160,
      },
    },
    content:
      '學到了一維、二維的陣列運用，以及字串處理、queue佇列等，利用Zerojudge平台練習，加強對題目的掌握度。',
    transcript: transcriptImg4,
    thumbnail: {
      left: thumbnailLeft4,
      right: thumbnailRight4,
    },
    avatar: avatarImg4,
  },
  {
    id: 'sung',
    name: 'Sung Lun-Yu',
    title: '師大附中 高二',
    courses: ['APCS 進階班', 'APCS 大師班'],
    score: {
      before: {
        concept: 64,
        practice: 220,
      },
    },
    content:
      '這門課讓我學到很多東西，在還未進入這個課前，我寫的程式常常都使用攏長且硬爆的程式碼來寫，不僅慢且出錯率高，但經過了幾堂課的洗禮，我學會如何透過簡潔有力的方式來寫出程式碼，並且最後也成功反應在我實作成績上，真的是收穫滿滿。',
    transcript: transcriptImg5,
    thumbnail: {
      left: thumbnailLeft5,
      right: thumbnailRight5,
    },
    avatar: avatarImg5,
  },
];
