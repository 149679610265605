import React from 'react';

import Accordion from 'react-bootstrap/Accordion';
import HeaderItem from 'components/Header/HeaderItem';

function MobileAccordion(props) {
  const { item } = props;
  const { list, title } = item;
  return (
    <>
      <Accordion className="c-accordion c-header-accordion">
        <Accordion.Item
          eventKey="0"
          className="c-accordion__item c-header-accordion__item"
        >
          <Accordion.Button className="c-accordion__toggle c-header-accordion__toggle d-flex flex-column flex-md-row align-items-start align-items-md-center">
            <h6 className="c-accordion__title c-header-accordion__title">
              {title}
            </h6>
          </Accordion.Button>
          <Accordion.Body className="c-accordion__body c-header-accordion__body">
            <ul className="c-list">
              {list.map((item) => {
                const { id } = item;
                return (
                  <HeaderItem
                    key={id}
                    layout="accordion"
                    linkLayout
                    item={item}
                  />
                );
              })}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}

export default MobileAccordion;
