import React from 'react';

import Title from 'components/Title';

import { precaution } from 'data/course';

function Precaution() {
  return (
    <>
      <section
        className="l-course__precaution l-precaution e-course-section"
        id="precaution"
      >
        <Title text="注意事項" align="start" />
        {precaution.map((item) => {
          const { id, title, content } = item;
          return (
            <ul
              key={id}
              className="c-list c-list--dot c-list--start l-precaution__list"
            >
              <li className="c-list__title">{title}</li>
              {content.map((item, i) => {
                return (
                  <li key={i} className="c-list__item">
                    {item}
                  </li>
                );
              })}
            </ul>
          );
        })}
      </section>
    </>
  );
}

export default Precaution;
