import React from 'react';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

const Item = (props) => {
  const { data } = props;
  const { title, content, date, views, img, link } = data;
  return (
    <>
      <div className="c-list-card__list-card c-list-card">
        <div className="row g-3">
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <div className="c-list-card__img">
              <img src={img} alt="活動照片" className="e-img e-img--cover" />
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-8 col-lg-9">
            <div className="d-flex flex-column justify-content-between h-100">
              <div className="c-list-card__top">
                <div className="c-list-card__heading">
                  <span className="c-list-card__text">
                    <FontAwesomeIcon
                      icon={solid('clock')}
                      className="l-header__icon e-icon e-icon--muted e-icon--right fa-fw"
                    />
                    {date}
                  </span>
                  <span className="c-list-card__text">
                    <FontAwesomeIcon
                      icon={solid('eye')}
                      className="l-header__icon e-icon e-icon--muted e-icon--right fa-fw"
                    />
                    {views}
                  </span>
                </div>
                <h6 className="c-list-card__title">{title}</h6>
                <p className="c-list-card__subtitle">{content}</p>
              </div>
              <Link
                to={link}
                className="c-list-card__action e-btn e-btn--primary e-btn--wmax"
              >
                課程介紹
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Item;
