import React, { useCallback, useEffect, useRef } from 'react';

import Particles from 'react-tsparticles';
import { loadSlim } from 'tsparticles-slim';

import particlesJSConfig from 'data/particlesjs-config.json';
const KeyVisual = ({ LAYOUT_CLASS }) => {
  const particlesInit = useCallback(async (engine) => {
    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);
  return (
    <>
      <div className={`${LAYOUT_CLASS}__key-visual c-particle`}>
        <Particles
          id="tsparticles"
          options={particlesJSConfig}
          init={particlesInit}
          loaded={particlesLoaded}
          width="100%"
        />
        <div className="c-particle__heading">
          <h1 className="c-particle__title">程式冬令營</h1>
          <p className="c-particle__subtitle">2024/1/20 - 2024/2/04</p>
        </div>
        <div className="c-particle__fg"></div>
        <div className="c-particle__bg"></div>
      </div>
    </>
  );
};

export default KeyVisual;
