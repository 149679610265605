import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro';

import {
  LINE_URL,
  FACEBOOK_URL,
  INSTAGRAM_URL,
  SERVICE_EMAIL,
} from 'utils/config';

function Footer() {
  return (
    <>
      <footer className="l-footer">
        <div className="row gx-0 justify-content-center w-100">
          <div className="col-10 col-lg-8 col-xl-8 d-flex flex-column align-items-center">
            <ul className="c-list l-footer__list">
              <li className="c-list__item l-footer__item">
                <a
                  className="e-btn e-btn--icon e-btn--opacity l-footer__btn"
                  href={`${LINE_URL}`}
                  role="button"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    icon={brands('line')}
                    className="c-list__icon l-footer__icon fa-fw"
                  />
                </a>
              </li>
              <li className="c-list__item l-footer__item">
                <a
                  className="e-btn e-btn--icon e-btn--opacity l-footer__btn"
                  href={`${FACEBOOK_URL}`}
                  role="button"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    icon={brands('facebook-square')}
                    className="c-list__icon l-footer__icon fa-fw"
                  />
                </a>
              </li>
              <li className="c-list__item l-footer__item">
                <a
                  className="e-btn e-btn--icon e-btn--opacity l-footer__btn"
                  href={`${INSTAGRAM_URL}`}
                  role="button"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    icon={brands('instagram-square')}
                    className="c-list__icon l-footer__icon fa-fw"
                  />
                </a>
              </li>
              <li className="c-list__item l-footer__item">
                <a
                  className="e-btn e-btn--icon e-btn--opacity l-footer__btn"
                  href={`mailto:${SERVICE_EMAIL}`}
                  role="button"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    icon={solid('envelope')}
                    className="c-list__icon l-footer__icon fa-fw"
                  />
                </a>
              </li>
            </ul>
            <div className="l-footer__text">
              Copyright © 2024 ThousandAI. All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
