import React from 'react';

import Title from 'components/Title';

const AdoptingInstitutes = (props) => {
  const { data } = props;
  const { title, subtitle, list } = data;
  return (
    <>
      <section className="l-course-intro__section u-section c-article__section">
        <Title text={title} align="start" />
        <h5 className="c-article__subtitle">{subtitle}</h5>
        <ul className="c-list c-list--item">
          {list.map((v, i) => {
            return (
              <li key={i} className="c-list__item">
                <span className="e-tag e-tag--outline">{v}</span>
              </li>
            );
          })}
        </ul>
      </section>
    </>
  );
};

export default AdoptingInstitutes;
