import React from 'react';
import Title from './Title';

const HeadingSection = () => {
  return (
    <>
      <section className="l-student-feedback__section u-section">
        <Title text="來自學員真實的感受" />
        <div className="row justify-content-center">
          <div className="col-11 col-md-10 col-md-10">
            <p className="l-student-feedback__text">
              ThousandAI帶領著學生們一起探索程式，而學生的回饋一直是我們最珍貴的財富。這些來自不同背景和年齡層的學生，通過我們的課程實現了個人成長和技能提升，他們的故事激勵著我們不斷前進。
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeadingSection;
