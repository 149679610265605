import React, { useState, useEffect } from 'react';

import Banner from 'components/Banner';
import Filter from 'components/LatestNews/Filter';
import Item from 'components/LatestNews/Item';

import { newsList } from 'data/latestNews';

const LatestNews = () => {
  const [active, setActive] = useState({
    category: 'all',
  });
  const [news, setNews] = useState([]);

  const isAllCategory = active.category === 'all';

  useEffect(() => {
    if (isAllCategory) {
      setNews([...newsList]);
    } else {
      const matchCategory = newsList.filter(
        (v) => v.category === active.category
      );
      setNews([...matchCategory]);
    }
  }, [active.category]);
  return (
    <>
      <Banner title="最新活動" subtitle="Latest News" />
      <div className="l-latest-news u-page u-page--placeholder">
        <div className="row gx-0 justify-content-center w-100">
          <div className="col-10 col-xl-8">
            <section className="l-latest-news__heading u-block">
              <Filter active={active} setActive={setActive} />
            </section>
            <section className="l-latest-news__content">
              {news.map((v) => {
                const { id } = v;
                return <Item key={id} data={v} />;
              })}
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default LatestNews;
