import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import LightGallery from 'lightgallery/react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

const Item = ({ data, alignLeft }) => {
  const {
    id,
    name,
    title,
    courses,
    score,
    content,
    thumbnail,
    transcript,
    avatar,
  } = data;

  const onInit = () => {
    // console.log('lightGallery has been initialized');
  };
  return (
    <>
      <div className="l-student-feedback__item">
        <div className="l-student-feedback__heading">
          <div
            className={`l-feedback__avatar order-1 order-lg-${
              alignLeft ? '0' : '1'
            } ${alignLeft ? '' : 'ms-3'}`}
          >
            <img
              className="e-img e-img--cover"
              src={avatar}
              alt="feedback-avatar"
            />
          </div>
          <div
            className={`d-flex flex-column w-100 order-2 order-lg-${
              alignLeft ? '1' : '0'
            } align-items-start ${alignLeft ? '' : 'align-items-lg-end'}`}
          >
            <h6 className="l-feedback__title">{name}</h6>
            <h5 className="l-feedback__subtitle">{title}</h5>
            <div className="d-flex align-items-center flex-wrap">
              {courses.map((item, i) => {
                const isOddItem = i % 2 === 0;
                return (
                  <span
                    key={item}
                    className="l-feedback__tag l-student-feedback__tag e-tag e-tag--icon"
                    data-odd={isOddItem}
                  >
                    {item}
                  </span>
                );
              })}
            </div>
          </div>
        </div>
        <div
          className="l-student-feedback__block"
          data-left={alignLeft.toString()}
        >
          <div className="d-flex flex-column flex-lg-row">
            <div
              className={`d-flex flex-column order-1 order-lg-${
                alignLeft ? '1' : '3'
              } mb-3 mb-lg-0`}
            >
              <div
                className={`d-flex flex-column flex-md-row align-items-center justify-content-md-center justify-content-lg-${
                  alignLeft ? 'start' : 'end'
                } mb-3`}
              >
                <h6 className="l-student-feedback__stress u-font--stress">
                  觀念題{score.before.concept}分、實作題{score.before.practice}
                  分
                </h6>
                {score.after && (
                  <>
                    <i className="l-student-feedback__icon mx-0 mx-md-3" />
                    <h6 className="l-student-feedback__stress u-font--stress">
                      觀念題{score.after.concept}分、實作題
                      {score.after.practice}分
                    </h6>
                  </>
                )}
              </div>
              <p className="l-student-feedback__content">{content}</p>
            </div>
            <div
              className="l-student-feedback__wrapper order-2 mb-3 mb-lg-0"
              data-left={alignLeft.toString()}
            >
              <LightGallery onInit={onInit} speed={500} plugins={[lgZoom]}>
                <button
                  href={transcript}
                  className="l-student-feedback__deco"
                  style={{
                    backgroundImage: `url(${
                      thumbnail[alignLeft ? 'right' : 'left']
                    })`,
                  }}
                ></button>
              </LightGallery>
            </div>
            {/* <div
              className={`align-self-center align-self-lg-end d-flex align-items-center text-nowrap order-3 order-lg-${
                alignLeft ? '3' : '1'
              }`}
            >
              <a
                href={transcript}
                className="e-btn e-btn--text e-btn--primary e-btn--wmax"
              >
                <FontAwesomeIcon
                  icon={solid('magnifying-glass')}
                  className="fa-fw e-icon"
                />
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Item;
