import React from 'react';

const Item = ({ LAYOUT_CLASS, data }) => {
  const { id, link, icon, title, content, price } = data;
  const BREAKPOINT = 'md';
  return (
    <>
      <div className={`col-12 col-sm-8 col-${BREAKPOINT}-6 col-lg-5`}>
        <div className={`${LAYOUT_CLASS}__course__card`}>
          <a
            href={link}
            className="stretched-link"
            target="_blank"
            rel="noreferrer"
          >
            <div
              className={`${LAYOUT_CLASS}__course__card__cover vstack gap-3 justify-content-center align-items-center`}
            >
              <img
                src={icon}
                alt=""
                className={`e-img e-img--contain ${LAYOUT_CLASS}__course__card__img`}
              />
              <h6 className={`${LAYOUT_CLASS}__subtitle`}>{title}</h6>
            </div>
            <div
              className={`${LAYOUT_CLASS}__course__card__content vstack gap-3`}
            >
              <p className={`${LAYOUT_CLASS}__text`}>{content}</p>
              <div className="d-flex align-items-center justify-content-between">
                <div className={`${LAYOUT_CLASS}__course__card__strong`}>
                  ${price.toLocaleString()}
                </div>
                <button
                  type="button"
                  className={`e-btn e-btn--text e-btn--wmax ${LAYOUT_CLASS}__course__card__btn`}
                >
                  {id.slice(0, 5) === 'unity' ? '報名' : '查看課程'}
                </button>
              </div>
            </div>
          </a>
        </div>
      </div>
    </>
  );
};

export default Item;
