import React from 'react';
import { Link } from 'react-router-dom';

const Item = ({ LAYOUT_CLASS, data }) => {
  const { index, icon, link, title, content } = data;
  return (
    <>
      <div className="col-12 col-md-6 col-xl-3">
        <Link to={link} className="h-100">
          <div
            className={`${LAYOUT_CLASS}__card__item`}
            data-index={index % 2 === 0 ? 'even' : 'odd'}
          >
            <div className={`${LAYOUT_CLASS}__card__icon`}>
              <img src={icon} alt={title} className="e-img e-img--contain" />
            </div>
            <h2 className={`${LAYOUT_CLASS}__card__title`}>{title}</h2>
            <p className={`${LAYOUT_CLASS}__card__text`}>{content}</p>
            <div className={`${LAYOUT_CLASS}__card__bg`} />
          </div>
        </Link>
      </div>
    </>
  );
};

export default Item;
