import React from 'react';

import Title from 'components/Title';

const Opening = (props) => {
  const { data } = props;
  const { title, content } = data;
  return (
    <>
      <section className="l-course-intro__section u-section c-article__section">
        <Title text={title} align="start" />
        <p className="c-article__paragraph">{content}</p>
      </section>
    </>
  );
};

export default Opening;
