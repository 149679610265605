import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { courseList } from 'data/course';

const Item = (props) => {
  const { data } = props;
  const { cn, en, title, edu, img, exp, courses } = data;
  return (
    <>
      <div className="l-faculty__col col-12 col-md-6 col-xl-4 d-flex align-items-stretch">
        <div className="l-faculty__card c-card c-card--more">
          <div className="l-faculty__section">
            <div className="row justify-content-center align-items-sm-center gx-4 gy-3">
              <div className="col-10 col-sm-6 col-md-10 col-lg-9 col-xxl-7">
                <div className="l-faculty__avatar">
                  <img
                    className="e-img e-img--cover"
                    src={img}
                    alt="feedback-avatar"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-10 col-lg-9 col-xxl-7">
                <div className="l-faculty__intro d-flex flex-column align-items-center">
                  <h6 className="l-faculty__title">
                    {en}
                    {cn}
                  </h6>
                  <h5 className="l-faculty__subtitle">{title}</h5>
                  <h5 className="l-faculty__subtitle">{edu}</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="l-faculty__section">
            <div className="c-title c-title--start c-title--sub">
              <h5 className="c-title__text">個人經歷</h5>
            </div>
            <ul className="c-list c-list--dot l-faculty__list">
              {exp.map((item) => {
                const { id, title } = item;
                return (
                  <li key={id} className="c-list__item">
                    {title}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="l-faculty__section">
            <div className="c-title c-title--start c-title--sub">
              <h5 className="c-title__text">授課課程</h5>
            </div>
            <div className="l-faculty__tags">
              {courses.map((v) => {
                const matchCourse = courseList.find(
                  (course) => course.id === v
                );
                const { id, title, category } = matchCourse;
                return (
                  <Link
                    key={id}
                    role="button"
                    to={`/course/${id}`}
                    className="l-faculty__tag e-tag e-tag--icon e-tag--link"
                    data-tag={category}
                  >
                    {title}
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Item;
