import Title from 'components/Title';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import swiperImg1 from 'img/home/pic/carousel-1.jpg';
import swiperImg2 from 'img/home/pic/carousel-2.jpg';
import swiperImg3 from 'img/home/pic/carousel-3.jpg';
import swiperImg4 from 'img/home/pic/carousel-4.jpg';
import swiperImg5 from 'img/home/pic/carousel-5.jpg';
import { Link } from 'react-router-dom';

const Content = () => {
  const PAGE_CLASS = 'l-carousel';

  // TODO:首頁-輪播
  // 改點擊連結
  const courseList = [
    {
      id: 1,
      img: swiperImg1,
      title: '暑期程式班',
      link: '/summer-camp',
    },
    {
      id: 2,
      img: swiperImg2,
      title: 'APCS系統性規劃',
      link: '/course-intro/apcs',
    },
    // {
    //   id: 3,
    //   img: swiperImg3,
    //   title: '國外大學推甄',
    //   link: '/study-abroad',
    // },
    {
      id: 4,
      img: swiperImg4,
      title: 'AI系列課程',
      link: '/course/ai',
    },
    {
      id: 5,
      img: swiperImg5,
      title: 'Unity遊戲班',
      link: '/',
    },
  ];

  return (
    <>
      <section
        className="l-home__carousel u-home-section d-none d-md-block"
        data-section="1"
      >
        <div className="row gx-0 justify-content-center w-100">
          <div className="col-12 col-md-10 col-lg-9 col-xl-10">
            <Title text="暑期資訊" align="center" />
            <section className={`${PAGE_CLASS}__swiper position-relative`}>
              <Swiper
                slidesPerView={1}
                speed={1000}
                loop={true}
                autoplay={{
                  delay: 3500,
                  disableOnInteraction: false,
                }}
                navigation={{
                  prevEl: `.${PAGE_CLASS}__swiper__action.prev`,
                  nextEl: `.${PAGE_CLASS}__swiper__action.next`,
                }}
                modules={[Autoplay, Navigation]}
                className={`${PAGE_CLASS}__swiper`}
              >
                {courseList.map((v) => {
                  const { id, title, img, link } = v;
                  return (
                    <SwiperSlide
                      key={id}
                      className={`${PAGE_CLASS}__swiper__item`}
                    >
                      <Link to={link} className="stretched-link">
                        <img
                          src={img}
                          alt={title}
                          className="e-img e-img--cover"
                        />
                      </Link>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <button
                className={`${PAGE_CLASS}__swiper__action prev c-swiper__action`}
              ></button>
              <button
                className={`${PAGE_CLASS}__swiper__action next c-swiper__action`}
              ></button>
            </section>
          </div>
        </div>
      </section>
    </>
  );
};

export default Content;
