import React, { useState, useEffect } from 'react';

import KeyVisual from 'components/Home/KeyVisual';
import Feedbacks from 'components/Home/Feedbacks/Content';
import Features from 'components/Home/Features';
import Quote from 'components/Home/Quote';
import CoursesContent from 'components/Home/Courses/Content';
import FacultyContent from 'components/Home/Faculty/Content';

import { courseList } from 'data/course';
import { Carousel } from 'components/Home/Carousel';

function Home() {
  const [matchedCourse, setMatchedCourse] = useState([]);
  const displayList = courseList.filter(
    (item) => item.id.slice(item.id.length - 1, item.id.length) === '1'
  );

  useEffect(() => {
    const matched = displayList.map((item) => {
      return courseList.find((course) => course.id === item.id);
    });
    const result = matched.filter((item) => item !== undefined);
    setMatchedCourse(result);
  }, []);
  return (
    <>
      <div className="l-home">
        <KeyVisual />
        <div className="l-home__content">
          <Quote />
          <Carousel />
          <CoursesContent />
          <FacultyContent />
          <Features />
          <Feedbacks />
        </div>
      </div>
    </>
  );
}

export default Home;
