import React from 'react';

const Banner = (props) => {
  const { title, subtitle, bannerStyle = 'default' } = props;
  return (
    <>
      <div className={`c-banner c-banner--${bannerStyle}`}>
        <div className="row justify-content-center gx-0 w-100">
          <div className="col-11 col-sm-12">
            <h2 className="c-banner__title">{title}</h2>
            <h4 className="c-banner__subtitle">{subtitle}</h4>
          </div>
        </div>
      </div>
    </>
  );
};

Banner.defaultProps = {
  style: 'default',
};

export default Banner;
