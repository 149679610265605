import facultyImg1 from 'img/home/avatar/faculty__thousand--2023.jpg';
import facultyImg2 from 'img/home/avatar/faculty__ann--2023.jpg';
import facultyImg3 from 'img/home/avatar/faculty__steven--2023.jpg';

import featureImg1 from 'img/home/pic/feature__webex.png';
import featureImg2 from 'img/home/pic/feature__overview.png';
import featureImg3 from 'img/home/pic/feature__slack.png';

import feekbackImg1 from 'img/home/avatar/feedback__michael.jpg';
import feekbackImg2 from 'img/home/avatar/feedback__connie.jpg';
import feekbackImg3 from 'img/home/avatar/feedback__sherry.jpg';
import feekbackImg4 from 'img/home/avatar/feedback__sunny.jpg';
import feekbackImg5 from 'img/home/avatar/feedback__ryan.jpg';
import feekbackImg6 from 'img/home/avatar/feedback__frank.jpg';

// 課程總覽
export const classList = [
  {
    courseId: 'pycs1',
    classId: '1',
    status: 'enrolling',
  },
  {
    courseId: 'apcs1',
    classId: '2',
    status: 'coming-soon',
  },
  {
    courseId: 'ai',
    classId: '1',
    status: 'coming-soon',
  },
  {
    courseId: 'unity',
    classId: '1',
    status: 'coming-soon',
  },
];

// 師資介紹
export const facultyList = [
  {
    id: 'thousand',
    cn: ' 千祐',
    en: 'Thousand',
    title: 'ThousandAI CEO',
    edu: '臺大電資學院博士生',
    img: facultyImg1,
    exp: [
      {
        id: 1,
        title: '華碩 電腦視覺 AI 研究員',
      },
      {
        id: 2,
        title: '中研院 長期生態核心觀測站 工程師',
      },
      {
        id: 3,
        title: '臺大資訊系統訓練班 講師',
      },
      {
        id: 4,
        title: '程式教育 10 年經驗',
      },
    ],
    courses: ['apcs1', 'apcs2', 'apcs3', 'pycs1', 'ai'],
  },
  {
    id: 'steven',
    cn: ' 羿賢',
    en: 'Steven',
    title: 'ThousandAI 教師',
    edu: '臺大電資學院博士生',
    img: facultyImg3,
    exp: [
      {
        id: 1,
        title: '臺大資訊系統訓練班 講師',
      },
      {
        id: 2,
        title: '奇點創意程式創客學院 駐點教師',
      },
      {
        id: 3,
        title: '程式教育 8 年經驗',
      },
    ],
    courses: ['apcs1', 'pycs1'],
  },
  {
    id: 'ann',
    cn: ' 安',
    en: 'Ann',
    title: 'ThousandAI 教師',
    edu: '臺大電資學院碩士生',
    img: facultyImg2,
    exp: [
      {
        id: 1,
        title: '華碩 電腦視覺 AI 研究員',
      },
      {
        id: 2,
        title: '程式教育 3 年經驗',
      },
    ],
    courses: ['pycs1'],
  },
];

// 教學特色
export const featureList = [
  {
    id: 1,
    title: '4-10人精緻小班制，全方位打造最好的學習環境',
    list: [
      `比起學科的單打獨鬥，程式更需要的是團隊合作，我們控制每班4-10人維持精緻品質。並且練習團隊協作，從與同儕間的討論中，來達到自主學習與成長。`,
      `班級人數適中，讓老師們能夠照顧到每一位同學，督促孩子完成練習、解答疑問，充分了解孩子的學習狀況。`,
      `小班制的設計，讓同學有更多機會來發表意見、展現完成的專案，讓孩子從學習程式上獲得成就感，對程式產生興趣與更大的學習意願。`,
    ],
    img: featureImg1,
  },
  {
    id: 2,
    title: '全套課程規劃完善，帶領孩子從零基礎到大神',
    list: [
      `我們設計了完善的全套課程，從零基礎、初階、中階，到進階的課程，循序漸進帶領著孩子一步一步邁入程式的殿堂。`,
      `清楚將每門課學習得到的技能標示於課程地圖上，讓家長對於孩子的學習進程有更好的掌握。`,
      `在我們精心規劃的課程下，學期中會檢視孩子的作業內容以及學習狀況讓孩子跟得上學習步伐，並且學期末給予孩子發表專案的經驗以及檢定測驗，與家長評估孩子未來如何繼續學習。`,
    ],
    img: featureImg2,
  },
  {
    id: 3,
    title: '使用 Slack 與 Google Meet，達到線上線下溝通零時差',
    list: [
      `在課程中，會配合使用通訊軟體 Slack (團隊協作) 及 Google Meet(視訊)，無視地形或疫情的阻礙，達到及時溝通。`,
      `在 Slack 中有開設每堂課專屬頻道，讓學生在其討論，老師適時給予指導，掌握孩子學習狀況。`,
      `老師會在 Slack 中與學生互動，即使不是上課時間，仍然可利用Slack來督促學生作業及專案進度。`,
      `學生可利用 Slack 的私訊功能，直接向老師發問，老師會做到24小時內專業即時解答。`,
    ],
    img: featureImg3,
  },
];

// 學員回饋
export const feedbackList = [
  {
    id: '1',
    name: 'Michael KOO',
    title: 'Research Assistant at Academia Sinica',
    content:
      'Learning by doing is the most important aspect when it comes to building your own deep learning/ML models, and I was fortunate to have Thousand as my guide. He walked me through not only the high level understanding of various topics, but also the low level details of code and Maths to reinforce my overall understanding. The learning experience was wholesome.',
    avatar: feekbackImg1,
    courses: ['AI DeepLearning'],
  },
  {
    id: '2',
    name: 'Connie CHEN',
    title: 'Cupertino High School',
    content:
      '從高一開始老師幫我補理化，都會從觀念慢慢講解到做題目。就算平日有問題也都可以問老師，老師也都很快的幫忙分析和解說。在老師的教導下成績也很快的提升了。後來上了程式老師除了從基礎邏輯開始講解同時也在學校課業方面給了我很多的幫助。有些通常會花比較多時間的題目老師也很積極以最有效率的方式教我如果理解還學會了如何靈活運用。本來自己很難理解的題目老師會給一點提示引導我學會教學內容。',
    avatar: feekbackImg2,
    courses: ['Python', 'AI DeepLearning'],
  },
  {
    id: '3',
    name: 'Sherry HSIEH',
    title: 'Emma Willard School',
    content:
      'Thousand is a very supportive teacher in the classroom where I feel comfortable asking any question. He took me through the smallest details such as setting up my environment to writing the backend with JavaScript. He would also set up homework that deepens my study of the subject after completing them.',
    avatar: feekbackImg3,
    courses: ['AI DeepLearning'],
  },
  {
    id: '4',
    name: 'Sunny HE',
    title: '國立政治大學金融系',
    content:
      '老師會帶領你用簡單的方法一步一步推算邏輯，而且難度會循序漸進、因材施教，不用害怕聽不懂！重點是老師很親切健談，讓我能夠快樂學習沒有負擔～',
    avatar: feekbackImg4,
    courses: ['Python'],
  },
  {
    id: '5',
    name: 'Ryan CHANG',
    title: '薇閣國中一年級',
    content:
      '學程式至今已經有一年半的時間，也從初階學到了中階。我的夢想是成為一位遊戲開發師，所以我很努力的朝著夢想邁進。當初會接觸程式語言也是因為興趣使然，也很好奇想知道遊戲到底是怎麼設計出來的，於是在老師的的鼓勵和爸爸陪伴之下，我開始了學習程式語言之路！在學習的過程中，我遇到了很多困難，但這些困難和挫折不但提升了我的挫折容忍力，也讓我的邏輯思考能力更上一層樓了。我覺得寫程式最大的成就感是：解決程式bug後的那一刻，會讓我的心情無比開心！謝謝 Thousand 老師的悉心指導，希望長大以後也能和他一樣厲害！',
    avatar: feekbackImg5,
    courses: ['C++', 'Python'],
  },
  {
    id: '6',
    name: 'Frank CHIOU',
    title: '薇閣國中一年級',
    content:
      '寫程式是一門非常困難、需要毅力的行業，只要一個微不足道的錯誤就會導致整個程式無法運作，一個Bug可能就需要花費長久的時間去修正，這也是老師厲害的地方，在每堂課中都可以用最快的速度去找出錯誤和解決方法。老師的課程中偶爾也會穿插一些較簡單有趣的部份，每次上課都不覺得枯燥乏味。困難的各式程式語言，他都可以以一個專精者的角度去解釋得很完善、簡單易懂。而每次需要他幫忙時，老師也是不辭辛勞的為每個學生做到最好、做我們最好的榜樣，完成整個專題研究。我在這段時間內真的獲益良多，從最基礎的Python到後來可以調整版面的HTML、CSS和邏輯思維的Javascript，老師都教得很好，如今老師要再度開課，我打從心裡推薦老師的課程。',
    avatar: feekbackImg6,
    courses: ['C++', 'Python'],
  },
];
