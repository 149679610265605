import React from 'react';

const Heading = (props) => {
  const { data } = props;
  const { name, title, avatar, courses } = data;
  return (
    <>
      <div className="l-feedback__avatar">
        <img
          className="e-img e-img--cover"
          src={avatar}
          alt="feedback-avatar"
        />
      </div>
      <div className="d-flex flex-column w-100">
        <h6 className="l-feedback__title">{name}</h6>
        <h5 className="l-feedback__subtitle">{title}</h5>
        <div className="d-flex align-items-center flex-wrap">
          {courses.map((item) => {
            return (
              <span
                className="l-feedback__tag e-tag e-tag--icon"
                data-tag={item}
                key={item}
              >
                {item}
              </span>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Heading;
