// 最新資訊
import newsImg1 from 'img/latest-news/pic/news--1.jpg';
import newsImg2 from 'img/latest-news/pic/news--2.jpg';

export const newsList = [
  {
    id: 1,
    category: 'course',
    title: '2023最強實戰課程開課！',
    content: `Thousand老師親自授課，實體+線上課程，8堂課打好C/C++ 基礎，加上7次作業&1次檢定，為學員6月APCS檢定做好最佳迎戰！`,
    date: '2023.02.25',
    img: newsImg1,
    link: '/course/apcs1',
  },
  // {
  //   id: 2,
  //   category: 'course',
  //   title: 'Unity 遊戲程式班開課！',
  //   content: `喜歡遊戲，也想嘗試自己做出小遊戲的同學絕對不能錯過！名額有限，先搶先贏`,
  //   date: '2023.02.25',
  //   views: 999,
  //   img: newsImg2,
  //   link: '/',
  // },
  // {
  //   id: 3,
  //   category: 'competition',
  //   title: 'Unity 遊戲程式班開課！',
  //   content: `喜歡遊戲，也想嘗試自己做出小遊戲的同學絕對不能錯過！名額有限，先搶先贏`,
  //   date: '2023.02.25',
  //   views: 999,
  //   img: newsImg2,
  //   link: '/',
  // },
];
