import React, { useEffect, useState } from 'react';
import Title from '../Title';
import { Tabs } from '../Tabs';
import { courseList } from 'data/summer-camp';
import { Calendar, Item } from '.';

const Content = ({ LAYOUT_CLASS }) => {
  const [active, setActive] = useState({
    tab: 'python',
  });

  const [data, setData] = useState(null);

  const BREAKPOINT = 'lg';
  const isFetchingData = data === null;

  useEffect(() => {
    setData(courseList.find((v) => v.id === active.tab));
  }, [active.tab]);

  return (
    <>
      <section className={`${LAYOUT_CLASS}__section u-section`}>
        <Title text="暑期班開課時間" deco="TIME" decoStyle="3" />
        <div className="vstack gap-5">
          <Tabs
            LAYOUT_CLASS={LAYOUT_CLASS}
            active={active}
            setActive={setActive}
            decoStyle="3"
          />
          {!isFetchingData && (
            <>
              <div className="row justify-content-center">
                <Calendar
                  LAYOUT_CLASS={LAYOUT_CLASS}
                  BREAKPOINT={BREAKPOINT}
                  data={data}
                />
              </div>
              <div className="row row-cols-1 row-cols-md-2 justify-content-center g-3">
                {data.class.map((o) => (
                  <Item
                    key={o.id}
                    LAYOUT_CLASS={LAYOUT_CLASS}
                    BREAKPOINT={BREAKPOINT}
                    data={data}
                    item={o}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Content;
