import React from 'react';
import { Parallax } from 'react-parallax';

import useWindowDimensions from 'hooks/useWindowDimensions';
import keyVisualImgHorizontal from 'img/home/pic/key-visual--horizontal.jpg';
import keyVisualImgVertical from 'img/home/pic/key-visual--vertical.jpg';
import keyVisualImgTablet from 'img/home/pic/key-visual--tablet.jpg';

import Slogan from 'components/Home/Slogan';

function KeyVisual(props) {
  const { width, height } = useWindowDimensions();

  return (
    <>
      <div className="l-key-visual">
        {width > 992 && width > height ? (
          <Parallax
            className="l-key-visual__img"
            bgImage={keyVisualImgHorizontal}
            strength={200}
          >
            <Slogan />
          </Parallax>
        ) : (
          <div className="l-key-visual__bg">
            <Slogan />
          </div>
        )}
      </div>
    </>
  );
}

export default KeyVisual;
