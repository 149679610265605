import React from 'react';

const IconPlaceholder = () => {
  return (
    <>
      <svg
        width="85"
        height="85"
        viewBox="0 0 85 85"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g mask="url(#mask0_1746_2513)">
          <path
            d="M28.9536 60.1876L18.9015 50.1356C16.955 48.189 16.955 45.0331 18.9015 43.0864C20.848 41.14 24.004 41.14 25.9505 43.0864L42.5019 59.6378V82.5098H21.5226V75.0391L7.47266 55.0081V30.0409C7.47266 27.288 9.70424 25.0564 12.4571 25.0564C15.21 25.0564 17.4416 27.288 17.4416 30.0409V46.6074"
            stroke="#606060"
            strokeWidth="4"
            strokeMiterlimit="10"
          />
          <path
            d="M56.0484 60.1876L66.1004 50.1356C68.047 48.189 68.047 45.0331 66.1004 43.0864C64.1539 41.14 60.998 41.14 59.0514 43.0864L42.5 59.6378V82.5098H63.4794V75.0391L77.5293 55.0081V30.0409C77.5293 27.288 75.2977 25.0564 72.5448 25.0564C69.792 25.0564 67.5604 27.288 67.5604 30.0409V46.6074"
            stroke="#606060"
            strokeWidth="4"
            strokeMiterlimit="10"
          />
          <path
            d="M51.457 10.0522C51.457 5.87596 54.8426 2.49024 59.019 2.49024C63.1953 2.49024 66.5809 5.87596 66.5809 10.0522C66.5809 14.2285 63.1953 17.6143 59.019 17.6143C54.8426 17.6143 51.457 14.2285 51.457 10.0522Z"
            stroke="#606060"
            strokeWidth="4"
            strokeMiterlimit="10"
          />
          <path
            d="M33.5457 10.0522C33.5457 5.87596 30.1602 2.49024 25.9837 2.49024C21.8074 2.49024 18.4219 5.87596 18.4219 10.0522C18.4219 14.2285 21.8074 17.6143 25.9837 17.6143C30.1602 17.6143 33.5457 14.2285 33.5457 10.0522Z"
            stroke="#606060"
            strokeWidth="4"
            strokeMiterlimit="10"
          />
          <path
            d="M50.0654 29.5542C50.0654 25.3779 46.6797 21.9922 42.5034 21.9922C38.3271 21.9922 34.9414 25.3779 34.9414 29.5542C34.9414 33.7305 38.3271 37.1162 42.5034 37.1162C46.6797 37.1162 50.0654 33.7305 50.0654 29.5542Z"
            stroke="#606060"
            strokeWidth="4"
            strokeMiterlimit="10"
          />
          <path
            d="M47.5664 23.9395C49.9604 20.139 54.1941 17.6139 59.0175 17.6139C64.373 17.6139 69.0015 20.7269 71.1921 25.2422"
            stroke="#606060"
            strokeWidth="4"
            strokeMiterlimit="10"
          />
          <path
            d="M37.4343 23.9395C35.0403 20.139 30.8066 17.6139 25.9832 17.6139C20.6277 17.6139 15.9992 20.7269 13.8086 25.2422"
            stroke="#606060"
            strokeWidth="4"
            strokeMiterlimit="10"
          />
          <path
            d="M55.4482 46.6929C53.7565 41.1488 48.6009 37.1158 42.5033 37.1158C36.4057 37.1158 31.2503 41.1488 29.5586 46.6929"
            stroke="#606060"
            strokeWidth="4"
            strokeMiterlimit="10"
          />
        </g>
      </svg>
    </>
  );
};

export default IconPlaceholder;
