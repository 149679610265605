import React from 'react';

import headingImg from 'img/summer-camp/heading.png';
import headingImg1 from 'img/summer-camp/heading-1.jpg';
import headingImg2 from 'img/summer-camp/heading-2.jpg';
import headingImg3 from 'img/summer-camp/heading-3.jpg';
import headingImg4 from 'img/summer-camp/heading-4.jpg';

import decoImg1 from 'img/summer-camp/deco-1.png';
import { Link } from 'react-router-dom';

const HeadingSection = ({ LAYOUT_CLASS }) => {
  const BREAKPOINT = 'md';
  return (
    <>
      <section
        className={`${LAYOUT_CLASS}__section u-section position-relative`}
      >
        <div className="row g-5 align-items-center">
          <div className={`col-12 col-${BREAKPOINT}-6`}>
            <div className="vstack gap-4">
              <h2
                className={`${LAYOUT_CLASS}__heading__title text-center text-${BREAKPOINT}-start`}
              >
                2024暑期班
                <br />
                提升實力的最佳時機！
              </h2>
              <p
                className={`${LAYOUT_CLASS}__text text-center text-${BREAKPOINT}-start`}
              >
                炎炎夏日，豐富你的暑假！參加我們的暑期班，快速學習程式設計技能。
                選擇Python x
                AI、APCS系列、Unity系列、圖靈計畫，輕鬆掌握基礎知識、提升解題技巧、實戰AI、開發遊戲專案、參加程式競賽，讓這個暑假更有意義！
              </p>
              {/* // TODO:暑期班資訊-了解課程內容 */}
              <Link
                to="/"
                className={`${LAYOUT_CLASS}__btn e-btn e-btn--primary e-btn--wmax align-self-center align-self-${BREAKPOINT}-start`}
              >
                了解課程內容
              </Link>
            </div>
          </div>
          <div className={`col-12 col-md-6`}>
            {/* <div className={`${LAYOUT_CLASS}__heading__wrapper row g-3`}>
              <div className="col-7">
                <div className={`${LAYOUT_CLASS}__heading__img`} data-img="1">
                  <img
                    src={headingImg1}
                    alt=""
                    className="e-img e-img--cover"
                  />
                </div>
                <div className={`${LAYOUT_CLASS}__heading__img`} data-img="2">
                  <img
                    src={headingImg3}
                    alt=""
                    className="e-img e-img--cover"
                  />
                </div>
              </div>
              <div className="col-5">
                <div className={`${LAYOUT_CLASS}__heading__img`} data-img="3">
                  <img
                    src={headingImg2}
                    alt=""
                    className="e-img e-img--cover"
                  />
                </div>
                <div className={`${LAYOUT_CLASS}__heading__img`} data-img="4">
                  <img
                    src={headingImg4}
                    alt=""
                    className="e-img e-img--cover"
                  />
                </div>
              </div>
            </div> */}
            <div className={`${LAYOUT_CLASS}__heading__wrapper row g-3`}>
              <img src={headingImg} alt="" className="e-img e-img--contain" />
            </div>
          </div>
        </div>
        <div className={`${LAYOUT_CLASS}__deco`} data-deco="1">
          <img src={decoImg1} alt="" />
        </div>
      </section>
    </>
  );
};

export default HeadingSection;
