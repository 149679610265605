import React, { useEffect, useState } from 'react';
import Title from '../Title';
import { courseList } from 'data/summer-camp';
import Item from './Item';
import { Tabs } from '../Tabs';

const Content = ({ LAYOUT_CLASS }) => {
  const [active, setActive] = useState({
    tab: 'python',
  });

  const BREAKPOINT = 'lg';

  return (
    <>
      <section className={`${LAYOUT_CLASS}__section u-section`}>
        <Title text="多樣性課程" deco="COURSE" decoStyle="1" />
        <div className="vstack gap-5">
          <Tabs
            LAYOUT_CLASS={LAYOUT_CLASS}
            active={active}
            setActive={setActive}
            decoStyle="1"
          />
          <div className="row justify-content-center">
            <div className={`col-12 col-${BREAKPOINT}-8 col-lg-10 col-xl-8`}>
              <div className="row justify-content-center g-5">
                {courseList
                  .find((v) => v.id === active.tab)
                  ?.list.map((v) => {
                    return (
                      <Item key={v.id} LAYOUT_CLASS={LAYOUT_CLASS} data={v} />
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Content;
