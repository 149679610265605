import iconImg1 from 'img/course/icon/python.svg';
import iconImg2 from 'img/course/icon/apcs.png';
import iconImg3 from 'img/course/icon/unity.svg';
import iconImg4 from 'img/course/icon/ytp.svg';

import calendarImg1 from 'img/summer-camp/calendar-python-apcs-july.jpg';
import calendarImg2 from 'img/summer-camp/calendar-python-apcs-aug.jpg';
import calendarImg3 from 'img/summer-camp/calendar-unity-july.jpg';
import calendarImg4 from 'img/summer-camp/calendar-python-apcs-aug.jpg';
import calendarImg5 from 'img/summer-camp/calendar-ytp-july.jpg';

import featureImg1 from 'img/summer-camp/feature-1.svg';
import featureImg2 from 'img/summer-camp/feature-2.svg';
import featureImg3 from 'img/summer-camp/feature-3.svg';
import featureImg4 from 'img/summer-camp/feature-4.svg';

// TODO:暑期班資訊-多樣性課程，滿足學習慾望
export const courseList = [
  {
    id: 'python',
    title: 'Python x AI',
    calendar: [calendarImg1, calendarImg2],
    list: [
      {
        id: 'python1',
        icon: iconImg1,
        title: 'Python x AI',
        content: `最流行的程式語言，最新潮的 AI 技術，以及扎實的系統化課程和完善的作業輔導，這門課是零基礎入門的最佳選擇。`,
        link: '/course/pycs1',
        price: 13000,
      },
    ],
    class: [
      // 行事曆下面的小卡片
      // TODO:自己按照行事曆的圈圈顏色去填colorStyle
      // 1淺藍 2橘 3黃 4深藍
      {
        id: 1,
        date: '7/2 ~ 7/12',
        time: '上午 9：30 ~ 12：00',
        href: 'https://www.surveycake.com/s/byNev',
        colorStyle: 2,
      },
      {
        id: 2,
        date: '7/16 ~ 7/26',
        time: '上午 9：30 ~ 12：00',
        href: 'https://www.surveycake.com/s/byNev',
        colorStyle: 1,
      },
      {
        id: 3,
        date: '8/6 ~ 8/16',
        time: '上午 9：30 ~ 12：00',
        href: 'https://www.surveycake.com/s/byNev',
        colorStyle: 2,
      },
      {
        id: 4,
        date: '8/20 ~ 8/29',
        time: '上午 9：30 ~ 12：00',
        href: 'https://www.surveycake.com/s/byNev',
        colorStyle: 1,
      },
    ],
  },
  {
    id: 'apcs',
    title: 'APCS 系列',
    calendar: [calendarImg1, calendarImg2],
    list: [
      {
        id: 'apcs1',
        icon: iconImg2,
        title: 'APCS 入門班',
        content: `最詳細的 C/C++ 語言課程，培養扎實的程式邏輯，建立縝密的解題思維，8堂課即可參加APCS檢定。`,
        link: '/course/apcs1',
        price: 15000,
      },
    ],
    class: [
      // 行事曆下面的小卡片
      // TODO:自己按照行事曆的圈圈顏色去填colorStyle
      // 1淺藍 2橘 3黃 4深藍
      {
        id: 1,
        title: 'APCS 入門班',
        date: '7/2 ~ 7/12',
        time: '下午 1：30 ~ 4：30',
        href: 'https://www.surveycake.com/s/Y8aVZ',
        colorStyle: 2,
      },
      {
        id: 2,
        title: 'APCS 入門班',
        date: '7/16 ~ 7/26',
        time: '下午 1：30 ~ 4：30',
        href: 'https://www.surveycake.com/s/Y8aVZ',
        colorStyle: 1,
      },
      {
        id: 3,
        title: 'APCS 入門班',
        date: '8/6 ~ 8/16',
        time: '下午 1：30 ~ 4：30',
        href: 'https://www.surveycake.com/s/Y8aVZ',
        colorStyle: 2,
      },
      {
        id: 4,
        title: 'APCS 入門班',
        date: '8/20 ~ 8/29',
        time: '下午 1：30 ~ 4：30',
        href: 'https://www.surveycake.com/s/Y8aVZ',
        colorStyle: 1,
      },
    ],
  },
  {
    id: 'unity',
    title: 'Unity 系列',
    calendar: [calendarImg3, calendarImg4],
    list: [
      {
        id: 'unity2d', //課程id獨立且唯一
        icon: iconImg3,
        title: 'Unity 2D 遊戲班',
        content: `探索遊戲開發的奧秘，學習如何打造遊戲並激發創意。通過掌握Unity技術，將開發兩款遊戲，踏上成為下一個遊戲創造者的旅程！`,
        link: 'https://www.surveycake.com/s/Z7eGG',
        price: 13000,
      },
      {
        id: 'unity3d', //課程id獨立且唯一
        icon: iconImg3,
        title: 'Unity 3D x AR',
        content: `深入探討3D原理與技術，學習開發射擊遊戲。手機平台上的AR增強實境開發，將技術成果應用於日常生活中，創造互動體驗。`,
        link: 'https://www.surveycake.com/s/Z7eGG',
        price: 13000,
      },
    ],
    class: [
      {
        id: 1,
        title: 'Unity 2D 遊戲班',
        date: '7/2 ~ 7/12',
        time: '下午 2：00 ~ 4：30',
        href: 'https://www.surveycake.com/s/Z7eGG',
        colorStyle: 2,
      },
      {
        id: 2,
        title: 'Unity 2D 遊戲班',
        date: '8/20 ~ 8/29',
        time: '下午 2：00 ~ 4：30',
        href: 'https://www.surveycake.com/s/Z7eGG',
        colorStyle: 1,
      },
      {
        id: 3,
        title: 'Unity 3D x AR 早上',
        date: '7/27 ~ 8/25',
        time: '上午 9：30 ~ 12：00',
        href: 'https://www.surveycake.com/s/Z7eGG',
        colorStyle: 4,
      },
      {
        id: 4,
        title: 'Unity 3D x AR 下午',
        date: '7/27 ~ 8/25',
        time: '下午 1：30 ~ 4：00',
        href: 'https://www.surveycake.com/s/Z7eGG',
        colorStyle: 4,
      },
    ],
  },
  {
    id: 'ytp',
    title: '圖靈計畫',
    calendar: [calendarImg5],
    list: [
      {
        id: 'ytp001',
        icon: iconImg4,
        title: '圖靈計畫 001班',
        content: `年度團隊程式大賽，密集式訓練，強化團隊默契與個人程式能力，提升學員整體表現，此課程為國中組設計。`,
        link: 'https://www.surveycake.com/s/4dO2g',
        price: 11000,
      },
      {
        id: 'ytp002',
        icon: iconImg4,
        title: '圖靈計畫 002班',
        content: `年度團隊程式大賽，密集式訓練，強化團隊默契與個人程式能力，提升學員整體表現，此課程為高中組設計。`,
        link: 'https://www.surveycake.com/s/4dO2g',
        price: 12800,
      },
    ],
    class: [
      {
        id: 1,
        title: '圖靈計畫 001班',
        date: '7/6 ~ 7/21',
        time: '上午 9：30 ~ 12：00',
        href: 'https://www.surveycake.com/s/4dO2g',
        colorStyle: 1,
      },
      {
        id: 2,
        title: '圖靈計畫 002班',
        date: '7/6 ~ 7/21',
        time: '下午 1：30 ~ 4：30',
        href: 'https://www.surveycake.com/s/4dO2g',
        colorStyle: 2,
      },
    ],
  },
];

// TODO:暑期班資訊-ThousandAI的特色
export const featureList = [
  {
    id: 1,
    title: 'Discord 平台',
    content:
      '利用Discord平台提供課後輔導，確保學員在作業過程中遇到問題能迅速解決，不致卡關。',
    icon: featureImg1,
  },
  {
    id: 2,
    title: '回家作業',
    content:
      '課後作業有助於學員內化所學知識，老師會提供協助，確保學員能在規定時間內順利完成。',
    icon: featureImg2,
  },
  {
    id: 3,
    title: '密集性訓練',
    content:
      '暑假期間，課程安排較為密集，通過集中式訓練，迅速提升學員的程式技能和邏輯思維。',
    icon: featureImg3,
  },
  {
    id: 4,
    title: '專業師資',
    content:
      '多樣性的課程，每堂課均由臺大電資學院的專業師資精心授課，確保學習品質優異',
    icon: featureImg4,
  },
];

// TODO:暑期班資訊-問答Q&A
export const qaList = [
  {
    id: '1',
    title: '實體課、線上課、數位影音課有什麼區別？',
    content: [
      {
        id: 1,
        title: '實體課',
        list: [
          '老師較好掌握學生學習狀況',
          '課程中有任何問題可以隨時提問',
          '課堂中同學們可以互相學習探討',
          '皆附數位影音課程可以看',
        ],
      },
      {
        id: 2,
        title: '線上課',
        list: [
          '在家即可學習',
          '有問題可以線上即時提出並解答',
          '皆附數位影音課程可以看',
        ],
      },
      {
        id: 3,
        title: '數位影音課程',
        list: [
          '隨時隨地都可以學習',
          '無限時間、次數的觀看',
          '有問題時可以與老師約時間討論',
        ],
      },
    ],
  },
  {
    id: '2',
    title: '請問我實體課上到一半想轉成線上課可以嗎？',
    content: '可以，會按照比例退還場地費 250元/堂，手續費則需自行負擔。',
  },
  {
    id: '3',
    title: '是否有試聽課程？',
    content: '有試聽課程，需要負擔一堂課的費用，且不能夠享有任何優惠。',
  },
  {
    id: '4',
    title: '實體課需要自備電腦去上課嗎？',
    content: '由於資訊安全疑慮，怕個人資料外漏，所以必須要自備電腦來上課。',
  },
  {
    id: '5',
    title: '課程期間請假的話，會導致進度落後嗎？',
    content: '每堂課都會有數位檔案，可以盡快看影片補上進度，不會導致進度落後。',
  },
  {
    id: '6',
    title: '如果沒有任何程式經驗，哪個課程比較適合我？',
    content:
      '若年齡為高中並且有想要參與 APCS 檢定，則可以從 APCS 入門班直接開始。若年齡為國中，則建議先從 Python 入門班慢慢打好基礎。',
  },
];
