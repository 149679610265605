import Title from 'components/Title';
import React from 'react';
import { Link } from 'react-router-dom';
import IconPlaceholder from '../IconPlaceholder';

const Content = ({ PAGE_CLASS }) => {
  const COMPONENT_CLASS = 'pros';

  const prosList = [
    {
      id: 1,
      title: '好處一',
      icon: <IconPlaceholder />,
      wrapperClassName: 'col-12 d-flex justify-content-center',
    },
    {
      id: 2,
      title: '好處二',
      icon: <IconPlaceholder />,
      wrapperClassName: 'col-6 d-flex justify-content-end',
    },
    {
      id: 3,
      title: '好處三',
      icon: <IconPlaceholder />,
      wrapperClassName: 'col-6 d-flex justify-content-start',
    },
  ];

  return (
    <>
      <div
        className={`${PAGE_CLASS}__${COMPONENT_CLASS} ${PAGE_CLASS}__section`}
        data-section="3"
      >
        <div className="row justify-content-center g-0">
          <div className="col-8">
            <div className="vstack gap-3 text-center">
              <Title text="國外推甄有什麼好處" align="center" />
              <p className={`${PAGE_CLASS}__text`}>
                我是國外推甄的基本簡介我是國外推甄的基本簡介我是國外推甄的基本簡介我是國外推甄的基本簡介我是國外推甄的基本簡介我是國外推甄的基本簡介我是國外推甄的基本簡介我是國外推甄的基本簡介我是國外推甄的基本簡介我是國外推甄的基本簡介我是國外推甄的基本簡介我是國外推甄的基本簡介我是國外推甄的基本簡介我是國外推甄的基本簡介我是國外推甄的基本簡介我是國外推甄的基本簡介我是國外推甄的基本簡介我是國外推甄的基本簡介
              </p>
              <div className="row g-5">
                {prosList.map((o) => (
                  <div key={o.id} className={o.wrapperClassName}>
                    <div
                      className={`${PAGE_CLASS}__${COMPONENT_CLASS}__item hstack gap-3`}
                    >
                      <div
                        className={`${PAGE_CLASS}__${COMPONENT_CLASS}__icon`}
                      >
                        {o.icon}
                      </div>
                      {o.title}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
