import React from 'react';

import Accordion from 'react-bootstrap/Accordion';

const Item = ({ LAYOUT_CLASS, data }) => {
  const { id, title, content } = data;
  return (
    <>
      <Accordion className={`${LAYOUT_CLASS}__qa__accordion`}>
        <Accordion.Item eventKey="0" className={`${LAYOUT_CLASS}__qa__item`}>
          <Accordion.Button className={`${LAYOUT_CLASS}__qa__toggle`}>
            <div className={`${LAYOUT_CLASS}__qa__icon`} data-type="Q">
              Q
            </div>
            {title}
          </Accordion.Button>
          <Accordion.Body className={`${LAYOUT_CLASS}__qa__content`}>
            <div className={`${LAYOUT_CLASS}__qa__icon`} data-type="A">
              A
            </div>
            {content}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default Item;
