import React from 'react';
import ReactGA from 'react-ga';

function PlanCard(props) {
  const { data } = props;
  const { title, img, prices, plans, signup } = data;

  const handleSignupClick = (url) => {
    // ReactGA.event({
    //   category: 'button',
    //   action: 'sign_up_click',
    //   label: 'Sign up',
    // });
    ReactGA.event({
      category: 'conversion',
      action: 'click',
      label: 'My Conversion',
      send_to: 'AW-11015970806/nNXRCPaNkaEYEPa_6YQp',
      event_callback: () => {
        if (url) {
          window.location = url;
        }
      },
    });
  };
  return (
    <>
      <div className="l-course__card c-course-card c-course-card--sticky u-section">
        <div className="c-course-card__img">
          <img src={img} alt="course-img" className="e-img e-img--cover" />
          <div className="c-course-card__title">{title}</div>
        </div>
        <div className="c-course-card__content">
          {prices.map((v) => {
            const { id, total } = v;
            let title;
            switch (id) {
              case 'classroom':
                title = '實體';
                break;
              case 'digital':
                title = '數位';
                break;
              case 'online':
              default:
                title = '線上';
                break;
            }
            return (
              <h6 key={id} className="c-course-card__highlight">
                {title}課程原價 ${total}
              </h6>
            );
          })}
          <hr className="e-hr c-course-card__hr" />
          <ul className="c-list c-list--dot c-list--start mb-3">
            {plans.map((item, i) => {
              const { id, title, subtitle, content } = item;
              return (
                <li key={id} className="c-list__item">
                  <div className="d-flex align-items-center">
                    <span className="e-tag mt-0">{title}</span>
                    <h6 className="c-course-card__subtitle">{subtitle}</h6>
                  </div>
                  <div className="c-course-card__text">{content}</div>
                </li>
              );
            })}
          </ul>
          <a
            href={signup}
            role="button"
            className="c-course-card__action e-btn e-btn--primary e-btn--w100"
            target="_blank"
            rel="noreferrer"
            onClick={handleSignupClick}
          >
            立即報名
          </a>
        </div>
      </div>
    </>
  );
}

export default PlanCard;
