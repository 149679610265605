import React from 'react';

import HeadingSection from 'components/StudentFeedback/HeadingSection';
import MarqueeSection from 'components/StudentFeedback/MarqueeSection';
import Item from 'components/StudentFeedback/Item';

import { studentList } from 'data/studentFeedback';

const StudentFeedback = () => {
  return (
    <>
      {/* <MarqueeSection /> */}
      <HeadingSection />
      <section className="l-student-feedback__main u-section">
        {studentList.map((v, i) => {
          const isOddItem = i % 2 === 0;
          return <Item key={v.id} data={v} alignLeft={isOddItem} />;
        })}
      </section>
    </>
  );
};

export default StudentFeedback;
