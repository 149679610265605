import React from 'react';

function Title(props) {
  const { text, align } = props;
  return (
    <>
      <div className={`c-title c-title--${align}`}>
        <h4 className="c-title__text">{text}</h4>
      </div>
    </>
  );
}

export default Title;
