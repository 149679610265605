import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Link } from 'react-router-dom';

const Item = ({ isActive, LAYOUT_CLASS, data, title, icon }) => {
  const { id, date, weekday, time, location, price, tutor, href } = data;
  return (
    <>
      <div className="col-12 col-md-8">
        <a href={href} target="blank">
          <div
            className={`${LAYOUT_CLASS}__schedule__item ${
              isActive ? 'e-ani--fade-in-down' : ''
            }`}
          >
            <div className={`${LAYOUT_CLASS}__schedule__top`}>
              <div className={`${LAYOUT_CLASS}__schedule__icon`}>
                <img src={icon} alt="" className="e-img e-img--contain" />
              </div>
            </div>
            <div className={`${LAYOUT_CLASS}__schedule__content`}>
              <div
                className={`${LAYOUT_CLASS}__schedule__row d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between`}
              >
                <h6 className={`${LAYOUT_CLASS}__schedule__title`}>{title}</h6>
                <div className={`${LAYOUT_CLASS}__schedule__tag e-tag m-0`}>
                  <FontAwesomeIcon
                    icon={solid('location-dot')}
                    className="fa-fw e-icon--right e-icon--winter"
                  />
                  {location}
                </div>
              </div>
              <hr className="e-hr" />
              <div className={`${LAYOUT_CLASS}__schedule__row mb-4`}>
                <div className="row gy-3 gy-md-0">
                  <div className="col-12 col-md-6">
                    <div className="d-flex align-items-start">
                      <FontAwesomeIcon
                        icon={solid('calendar')}
                        className="fa-fw e-icon e-icon--right e-icon--winter"
                      />
                      <div className="d-flex flex-column">
                        <span className={`${LAYOUT_CLASS}__schedule__text`}>
                          {date}
                        </span>
                        <span className={`${LAYOUT_CLASS}__schedule__text`}>
                          {time}
                        </span>
                        <span className={`${LAYOUT_CLASS}__schedule__text`}>
                          {weekday}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="d-flex align-items-start">
                      <FontAwesomeIcon
                        icon={solid('dollar-sign')}
                        className="fa-fw e-icon e-icon--right e-icon--winter"
                      />{' '}
                      <div className="d-flex flex-column">
                        {price.map((v, i) => {
                          return (
                            <span
                              key={i}
                              className={`${LAYOUT_CLASS}__schedule__text`}
                            >
                              {v}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${LAYOUT_CLASS}__schedule__row d-flex align-items-center justify-content-between`}
              >
                <div className="d-flex align-items-center">
                  <div className={`${LAYOUT_CLASS}__schedule__avatar`}>
                    <img
                      src={tutor.avatar}
                      alt={tutor.name}
                      className="e-img e-img--cover"
                    />
                  </div>
                  <span className={`${LAYOUT_CLASS}__schedule__text`}>
                    {tutor.name}
                  </span>
                </div>
                <button
                  type="button"
                  className={`e-btn e-btn--wmax e-btn--text ${LAYOUT_CLASS}__schedule__btn`}
                >
                  報名
                </button>
              </div>
            </div>
          </div>
        </a>
      </div>
    </>
  );
};

export default Item;
