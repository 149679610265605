import React from 'react';

import { Accordion } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import Banner from 'components/Banner';
import Title from 'components/Title';

import { competitionList, monthList } from 'data/competitionTraining';

const CompetitionTraining = () => {
  return (
    <>
      <Banner title="競賽培訓" subtitle="Competition Training" />
      <div className="l-competition-training">
        <div className="row gx-0 justify-content-center w-100">
          <div className="col-10 col-xl-8">
            <article className="u-page u-page--placeholder c-article">
              <section className="l-competition-training__heading u-section">
                <Title text="競賽介紹" align="start" />
                <p className="c-article__paragraph">
                  程式競賽培訓是一種培養學生編程能力、解決問題和思維能力的訓練過程，且競賽成果有助於學生在未來升學規劃中起到加分的作用。為此ThousandAI整理出各項國際與全國知名程式競賽，開班培訓，幫助學生獲得好的競賽成績。
                </p>
              </section>
              <section className="l-competition-training__graph u-section">
                <div className="l-competition-training__timeline"></div>
                {monthList.map((v) => {
                  const { id, month, list } = v;
                  const isEven = id % 2 === 0;
                  return (
                    <div
                      key={id}
                      className={`l-competition-training__peroid row g-5 ${
                        isEven ? 'flex-lg-row-reverse' : ''
                      }`}
                    >
                      <div className="d-none d-lg-flex col-lg-6"></div>
                      <div className="col-12 col-lg-6">
                        <div
                          className="l-competition-training__block"
                          data-dir={isEven ? 'left' : 'right'}
                        >
                          <div className="l-competition-training__time">
                            {month}月
                          </div>
                          <Accordion
                            className="l-competition-training__accordion"
                            defaultActiveKey={id === 1 && list[0].competitionId}
                          >
                            {list.map((v) => {
                              const { competitionId, isClass } = v;
                              const matchCompetition = competitionList.find(
                                (item) => item.id === v.competitionId
                              );
                              const {
                                id,
                                title,
                                content,
                                time,
                                target,
                                website,
                              } = matchCompetition;
                              return (
                                <Accordion.Item
                                  key={id}
                                  eventKey={id}
                                  className="l-competition-training__item"
                                >
                                  <Accordion.Button className="l-competition-training__toggle">
                                    {title}
                                    {isClass && (
                                      <span className="l-competition-training__tag e-tag e-tag--primary">
                                        開課中
                                      </span>
                                    )}
                                  </Accordion.Button>
                                  <Accordion.Body className="l-competition-training__body">
                                    <div className="l-competition-training__content">
                                      {content}
                                      <ul className="c-list">
                                        <li className="c-list__item">
                                          <FontAwesomeIcon
                                            icon={solid('clock')}
                                            className="c-list__icon e-icon e-icon--primary fa-fw"
                                          />
                                          <span className="l-competition-training__text">
                                            {time}
                                          </span>
                                        </li>
                                        <li className="c-list__item">
                                          <FontAwesomeIcon
                                            icon={solid('user')}
                                            className="c-list__icon e-icon e-icon--primary fa-fw"
                                          />
                                          <span className="l-competition-training__text">
                                            {target}
                                          </span>
                                        </li>
                                        <li className="c-list__item">
                                          <a
                                            href={website}
                                            className="l-competition-training__text e-link"
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <FontAwesomeIcon
                                              icon={solid('link')}
                                              className="c-list__icon e-icon e-icon--primary fa-fw"
                                            />
                                            官網連結
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>
                              );
                            })}
                          </Accordion>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </section>
            </article>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompetitionTraining;
