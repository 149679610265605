import React, { useEffect, useState } from 'react';
import Tabs from './Tabs';
import { courseList } from 'data/winter-camp';
import Item from './Item';
import { QA } from './QA';

const Content = ({ LAYOUT_CLASS }) => {
  const [active, setActive] = useState({
    tab: 'python',
  });

  const [activeCourse, setActiveCourse] = useState(null);

  const isFetchingActiveCourse = activeCourse === null;

  useEffect(() => {
    const matchedCourse = courseList.find((v) => v.id === active.tab);
    setActiveCourse({ ...matchedCourse });
  }, [active]);

  return (
    <>
      <div className={`${LAYOUT_CLASS}__main`}>
        <div className={`${LAYOUT_CLASS}__main__content`}>
          <div className="u-section">
            <h1 className={`${LAYOUT_CLASS}__title`}>班次介紹</h1>
            <Tabs
              LAYOUT_CLASS={LAYOUT_CLASS}
              active={active}
              setActive={setActive}
            />
            <div className="row justify-content-center">
              {!isFetchingActiveCourse &&
                courseList
                  .find((v) => v.id === active.tab)
                  ?.list.map((v) => {
                    const isActive = active.tab === activeCourse.id;
                    return (
                      <Item
                        key={v.id}
                        isActive={isActive}
                        LAYOUT_CLASS={LAYOUT_CLASS}
                        data={v}
                        title={activeCourse.title}
                        icon={activeCourse.icon}
                      />
                    );
                  })}
            </div>
          </div>
          <QA LAYOUT_CLASS={LAYOUT_CLASS} />
        </div>
      </div>
    </>
  );
};

export default Content;
