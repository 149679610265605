import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { useCourseData } from 'utils/context';
import { skillList } from 'data/course';

function Sidebar() {
  const { courseData } = useCourseData();
  const { age, priorKnowledge, including, skills } = courseData;
  return (
    <>
      <aside className=" l-course__sidebar c-course-card">
        <ul className="c-list">
          <li className="c-list__item">
            <strong className="c-list__title">
              <FontAwesomeIcon
                icon={solid('check')}
                className="c-list__icon fa-fw"
              />
              適合
            </strong>
            {age}
          </li>
          <li className="c-list__item">
            <strong className="c-list__title">
              <FontAwesomeIcon
                icon={solid('check')}
                className="c-list__icon fa-fw"
              />
              適合
            </strong>
            {priorKnowledge}
          </li>
          {including.map((v) => {
            const { id, num } = v;
            let title;
            switch (id) {
              case 'mid-term':
                title = '期中檢定';
                break;
              case 'final':
                title = '期末檢定';
                break;
              case 'project':
                title = '專題';
                break;
              case 'homework':
              default:
                title = '作業';
                break;
            }
            return (
              num !== 0 && (
                <li key={id} className="c-list__item">
                  <strong className="c-list__title">
                    <FontAwesomeIcon
                      icon={solid('check')}
                      className="c-list__icon fa-fw"
                    />
                    包含
                  </strong>
                  {num}個{title}
                </li>
              )
            );
          })}
          <li className="c-list__item">
            <strong className="c-list__title c-list__title--tag">
              <FontAwesomeIcon
                icon={solid('check')}
                className="c-list__icon fa-fw"
              />
              你會學到
            </strong>
            <div className="d-flex align-items-center flex-wrap">
              {skills.map((item) => {
                const matchedSkill = skillList.find(
                  (skill) => skill.title === item
                );
                if (matchedSkill) {
                  const { id, title, link } = matchedSkill;
                  return link ? (
                    <a
                      key={id}
                      className="e-tag e-tag--icon e-tag--link"
                      data-tag={title}
                      href={link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {title}
                    </a>
                  ) : (
                    <div
                      key={id}
                      className="e-tag e-tag--icon"
                      data-tag={title}
                    >
                      {title}
                    </div>
                  );
                }
              })}
            </div>
          </li>
        </ul>
      </aside>
    </>
  );
}

export default Sidebar;
