import React from 'react';
import Item from './Item';
import { courseList } from 'data/winter-camp';

const Content = ({ LAYOUT_CLASS }) => {
  return (
    <>
      <div className="row g-0">
        {courseList.map((v) => {
          return <Item key={v.id} LAYOUT_CLASS={LAYOUT_CLASS} data={v} />;
        })}
      </div>
    </>
  );
};

export default Content;
