import React from 'react';

import Title from 'components/Title';

const GradingSystem = (props) => {
  const { data } = props;
  const { title, subtitle, list } = data;
  return (
    <>
      <section className="l-course-intro__section u-section c-article__section">
        <Title text={title} align="start" />
        <h5 className="c-article__subtitle">{subtitle}</h5>
        <ul className="l-course-intro__list">
          {list.map((v) => {
            const { id, title, content, hint } = v;
            const isEven = id % 2 === 0;
            return (
              <li key={id} className="l-course-intro__item">
                <div className="d-block position-relative">
                  <div
                    className="l-course-intro__deco"
                    data-color={isEven}
                  ></div>
                  <span className="e-tag e-tag--right" data-color={isEven}>
                    {title}
                  </span>
                  {content}
                </div>
                <h6 className="u-font--stress">{hint}</h6>
              </li>
            );
          })}
        </ul>
      </section>
    </>
  );
};

export default GradingSystem;
