import cardImg from 'img/home/pic/category__competition-training.jpg';

// 學習地圖
export const pathList = [
  {
    id: 1,
    title: '基礎入門',
    content: `Python 是一門非常適合初學者的程式語言，因為它易於學習且具有很高的可讀性，而且常常是最流行與實用的語言之一。`,
    courseIds: ['pycs1'],
  },
  {
    id: 2,
    title: '解題競賽',
    content: `根據各項不同的程式競賽，規劃不同的訓練課程提升學生自身能力。競賽包含：YTP少年圖靈、NPSC、ISSC、HPE Codewar...等知名競賽。`,
    courseIds: ['apcs1', 'apcs2', 'apcs3'],
    btns: [
      {
        id: 1,
        title: '競賽培訓',
        img: cardImg,
        introduction: `程式競賽培訓是一種培養學生編程能力、解決問題和思維能力的訓練過程，且競賽成果有助於學生在未來升學規劃中起到加分的作用。為此ThousandAI整理出各項國際與全國知名程式競賽，開班培訓，幫助學生獲得好的競賽成績。`,
        link: '/competition-training',
      },
    ],
  },
  {
    id: 3,
    title: '實務專案',
    content: `暑期限定課程，帶領學生共同製作專案。讓學生能更深入探索興趣所在。`,
    courseIds: ['ai'],
  },
];
