import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Offcanvas from 'react-bootstrap/Offcanvas';

import HeaderDropdown from 'components/Header/HeaderDropdown';
import MobileAccordion from 'components/Header/MobileAccordion';
import HeaderItem from 'components/Header/HeaderItem';

import { headerList } from 'data/common';

function MobileOffcanvas(props) {
  const { pathname } = useLocation();
  const [selected, setSelected] = useState('');

  useEffect(() => {
    setSelected('');
  }, [pathname]);

  return (
    <>
      <Offcanvas.Header className="l-header__top" />
      <Offcanvas.Body bsPrefix="l-header__body">
        <ul className="l-header__list d-none d-xl-flex">
          {headerList.map((item) => {
            const { id, list, title, link, blank } = item;
            return list ? (
              <li key={id} className="l-header__item">
                <HeaderDropdown
                  key={id}
                  item={item}
                  selected={selected}
                  setSelected={setSelected}
                />
              </li>
            ) : (
              <HeaderItem key={id} item={item} />
            );
          })}
        </ul>
        <div className="l-header__accordion d-block d-xl-none w-100">
          {headerList.map((item) => {
            const { id, list, title, link, blank } = item;
            return list ? (
              <MobileAccordion key={id} item={item} />
            ) : (
              <HeaderItem
                key={id}
                layout="accordion"
                linkLayout={false}
                item={item}
              />
            );
          })}
        </div>
      </Offcanvas.Body>
      {/* <li key={id} className="l-header__item c-header-accordion__item">
        {blank ? (
          <a
            href={link}
            className="l-header__link c-header-accordion__link c-header-accordion__link--accordion"
            target="_blank"
            rel="noreferrer"
          >
            {title}
          </a>
        ) : (
          <Link
            to={link}
            className="l-header__link c-header-accordion__link c-header-accordion__link--accordion"
          >
            {title}
          </Link>
        )}
      </li> */}
    </>
  );
}

export default MobileOffcanvas;
