import React from 'react';
import { Link } from 'react-router-dom';

const Item = ({ LAYOUT_CLASS, BREAKPOINT, data, item }) => {
  const { title, date, time, href, colorStyle } = item;

  return (
    <>
      <div className={`col position-relative`}>
        <div
          className={`${LAYOUT_CLASS}__time__card hstack  justify-content-between`}
          data-color={String(colorStyle)}
        >
          <div className="vstack gap-2">
            <h5 className={`${LAYOUT_CLASS}__subtitle`}>
              {title ? title : data.title}
            </h5>
            <div className={`${LAYOUT_CLASS}__text`}>{date}</div>
            <div className={`${LAYOUT_CLASS}__text`}>{time}</div>
          </div>
          <a
            href={href}
            className={`stretched-link e-btn e-btn--wmax e-btn--primary ${LAYOUT_CLASS}__time__btn`}
            data-color={String(colorStyle)}
          >
            報名
          </a>
        </div>
      </div>
    </>
  );
};

export default Item;
