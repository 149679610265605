// 課程介紹
export const courseIntroList = [
  {
    id: 'apcs',
    title: 'APCS課程',
    subtitle: '多元入學特殊選才的重要指標',
    opening: {
      title: '何謂APCS',
      content:
        'APCS 是指「大學程式設計先修檢測」，是目前衡量國高中生程式能力最有公信力的一個指標。目前共有 47 所校系，在個人申請中設立 APCS 組，總計開放 118 個名額，提供具有程式能力的學生額外的入學管道。',
    },
    subjectsAndTypes: {
      title: 'APCS的檢測科目與題型',
      subtitle: 'APCS 分成兩大部分：觀念題與實作題',
      list: [
        {
          id: 1,
          title: '觀念題',
          content: '分為兩節測驗，共計40題，滿分100',
        },
        {
          id: 2,
          title: '實作題',
          content: '一節測驗，共計4個題組，滿分400',
        },
      ],
    },
    gradingSystem: {
      title: 'APCS計分方式',
      subtitle: '總共分為5個級分',
      list: [
        {
          id: 1,
          title: '1級分',
          content: '觀念題：0～29分，實作題：0～49分',
          hint: '尚未具備基礎程式設計能力',
        },
        {
          id: 2,
          title: '2級分',
          content: '觀念題：30～49分，實作題：50～149分',
          hint: '具備基礎程式設計能力',
        },
        {
          id: 3,
          title: '3級分',
          content: '觀念題：50～69分，實作題：150～249分',
          hint: '具備基礎程式設計與基礎資料結構運用能力',
        },
        {
          id: 4,
          title: '4級分',
          content: '觀念題：70～89分，實作題：250～349分',
          hint: '具備程式設計與基礎資料結構運用能力',
        },
        {
          id: 5,
          title: '5級分',
          content: '觀念題：90～100分，實作題：350～400分',
          hint: '具備常見資料結構與基礎演算程序運用能力',
        },
      ],
    },
    adoptingInstitutes: {
      title: '採納APCS成績的大學',
      subtitle:
        '據112學年度個人申請公告顯示，共有59個資訊相關科系將「大學程式設計先修檢測（APCS）」成績列入第一階段檢定、篩選項目中。',
      list: [
        '國立台灣大學－資訊工程學系（APCS組）',
        '國立清華大學－資訊工程學系（APCS組）',
        '國立陽明交通大學－資訊工程學系（APCS組）',
        '國立成功大學－資訊工程學系（APCS組）',
        '國立政治大學－資訊科學系（APCS組）',
      ],
    },
    introduction: {
      title: '課程介紹',
      content:
        '隨著科技日新月異，人工智慧的崛起促使資工系以黑馬之姿，快速提升成一大熱門科系。由於資工系的熱門，使得越來越多人注意到APCS的重要性，因此ThousandAI制訂一系列課程，讓學生能用較短的時間快速吸收，獲得好成績。',
      list: [
        {
          id: 1,
          title: '沒有程式基礎：',
          courseIds: ['pycs1'],
        },
        {
          id: 2,
          title: '有程式基礎：',
          courseIds: ['apcs1', 'apcs2', 'apcs3'],
        },
      ],
    },
  },
];
