import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Banner from 'components/Banner';
import Nav from 'components/Course/Nav';
import Info from 'components/Course/Info';
import Outline from 'components/Course/Outline';
import Intro from 'components/Course/Intro';
import PlanCard from 'components/Course/PlanCard';
import Precaution from 'components/Course/Precaution';
import CourseList from 'components/CourseList';

import { courseList } from 'data/course';
import { useCourseData } from 'utils/context';

function Course() {
  const { courseId } = useParams();
  const { courseData, setCourseData } = useCourseData();
  const [matchedCourse, setMatchedCourse] = useState([]);

  const isUndefinedCourseId =
    courseList.findIndex((item) => courseId === item.id) === -1;

  useEffect(() => {
    if (!isUndefinedCourseId) {
      const courseData = courseList.find((item) => courseId === item.id);
      setCourseData(courseData);
    }
  }, [courseId]);

  useEffect(() => {
    const matched = courseData.more.map((item) => {
      return courseList.find((course) => course.id === item);
    });
    const result = matched.filter((item) => item !== undefined);
    setMatchedCourse(result);
  }, [courseData]);

  const isFetchingData = courseData.id === '';

  return (
    <>
      {!isFetchingData && (
        <>
          <Banner title={courseData.title} subtitle={courseData.subtitle} />
          <div className="l-course u-page">
            <div className="row gx-0 justify-content-center w-100">
              <div className="col-10 col-xl-8">
                <div className="row gx-0 gx-md-3 gx-lg-5 gy-5">
                  <div className="col-12">
                    <Intro />
                  </div>
                  <div className="col-12 col-md-6 col-lg-7">
                    <Outline />
                    <Info />
                    {/* <Precaution /> */}
                  </div>
                  <div className="col-12 col-md-6 col-lg-5">
                    <PlanCard data={courseData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CourseList title="更多課程" data={matchedCourse} />
        </>
      )}
    </>
  );
}

export default Course;
