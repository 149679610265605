import React from 'react';

import Title from 'components/Title';

import { useCourseData } from 'utils/context';

function Outline() {
  const { courseData } = useCourseData();
  return (
    <>
      <section className="l-course__outline u-section" id="outline">
        <Title text="課程大綱" align="start" />
        <div className="l-course__img">
          <img
            src={courseData.outline}
            alt="outline"
            className="e-img e-img--contain"
          />
        </div>
      </section>
    </>
  );
}

export default Outline;
