import React from 'react';
import { qaList } from 'data/winter-camp';
import Item from './Item';

const Content = ({ LAYOUT_CLASS }) => {
  return (
    <>
      <div className={`${LAYOUT_CLASS}__qa`}>
        <h1 className={`${LAYOUT_CLASS}__title`}>Q&A</h1>
        <div className="u-section">
          {qaList.map((v) => {
            return <Item key={v.id} LAYOUT_CLASS={LAYOUT_CLASS} data={v} />;
          })}
        </div>
      </div>
    </>
  );
};

export default Content;
