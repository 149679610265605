import { courseList } from 'data/winter-camp';
import React, { useState } from 'react';

const Tabs = ({ LAYOUT_CLASS, active, setActive }) => {
  const handleTabClick = (e) => {
    const tab = e.currentTarget.getAttribute('data-tab');
    setActive({ ...active, tab });
  };
  return (
    <>
      <div className={`${LAYOUT_CLASS}__schedule__tabs`}>
        <div className="row gy-2 gy-lg-0">
          {courseList.map((v) => {
            return (
              <div key={v.id} className="col-12 col-md-6 col-lg-3 d-flex">
                <button
                  type="button"
                  className={`e-btn e-btn--text e-btn--w100 ${LAYOUT_CLASS}__schedule__tab ${
                    active.tab === v.id ? 'active' : ''
                  }`}
                  data-tab={v.id}
                  onClick={handleTabClick}
                >
                  {v.title}
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Tabs;
