import React from 'react';

import { courseList } from 'data/summer-camp';

const Main = ({ LAYOUT_CLASS, active, setActive, decoStyle }) => {
  const handleTabClick = (e) => {
    const tab = e.currentTarget.getAttribute('data-tab');
    setActive({ ...active, tab });
  };
  return (
    <>
      <div className={`${LAYOUT_CLASS}__course__tabs`}>
        <div className="hstack align-items-center justify-content-center gap-3">
          {courseList.map((v) => {
            return (
              <button
                key={v.id}
                type="button"
                className={`e-btn e-btn--text e-btn--wmax ${LAYOUT_CLASS}__course__tab ${
                  active.tab === v.id ? 'active' : ''
                }`}
                data-tab={v.id}
                data-style={decoStyle}
                onClick={handleTabClick}
              >
                {v.title}
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Main;
