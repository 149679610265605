import React from 'react';

import Title from 'components/Title';
import Item from 'components/Home/Faculty/Item';

import { facultyList } from 'data/home';

function Content() {
  return (
    <>
      <section
        className="l-home__faculty l-faculty l-faculty--card u-home-section"
        data-section="3"
      >
        <div className="row gx-0 justify-content-center w-100">
          <div className="col-10 col-lg-9 col-xl-11 col-xxl-10">
            <Title text="師資陣容" align="center" />
            <main className="l-faculty__main">
              <div className="row gy-5">
                {facultyList.map((v) => {
                  const { id } = v;
                  return <Item key={id} data={v} />;
                })}
              </div>
            </main>
          </div>
        </div>
      </section>
    </>
  );
}

export default Content;
