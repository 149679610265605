import React from 'react';

import Title from 'components/Title';
import CourseItem from 'components/CourseItem';

import { courseList } from 'data/course';

const Introduction = (props) => {
  const { data } = props;
  const { title, content, list } = data;
  return (
    <>
      <section className="l-course-intro__section u-section c-article__section">
        <Title text={title} align="start" />
        <p className="c-article__paragraph">{content}</p>
        <ul className="c-list c-list--block">
          {list.map((v) => {
            const { id, title, courseIds } = v;
            const matchCourses = courseIds.map((v) => {
              return courseList.find((course) => course.id === v);
            });
            return (
              <li key={id} className="c-list__item">
                <span className="c-article__subtitle u-font--stress">
                  {title}
                </span>
                <div className="row gy-3">
                  {matchCourses.map((v) => {
                    const { id } = v;
                    return <CourseItem key={id} data={v} col="6" />;
                  })}
                </div>
              </li>
            );
          })}
        </ul>
      </section>
    </>
  );
};

export default Introduction;
