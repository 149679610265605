import React from 'react';

const Calendar = ({ LAYOUT_CLASS, BREAKPOINT, data }) => {
  return (
    <>
      {data.calendar.map((v, i) => (
        <div key={i} className={`col-12 col-sm-8 col-${BREAKPOINT}-6 col-lg-5`}>
          <div className={`${LAYOUT_CLASS}__time__calendar`}>
            <img
              src={v}
              alt={`${data.title} ${i === 0 ? '7' : '8'}月開課行事曆`}
              className="e-img e-img--contain"
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default Calendar;
