import React from 'react';

import Title from 'components/Title';
import Item from 'components/Home/Feedbacks/Item';
import MobileFeedbackList from 'components/Home/Feedbacks/MobileList';

import { feedbackList } from 'data/home';

function Content() {
  return (
    <>
      <section
        className="l-home__feedback l-feedback u-home-section"
        data-section="5"
      >
        <div className="row gx-0 justify-content-center w-100">
          <div className="col-10 col-lg-9 col-xl-8">
            <Title text="學員回饋" align="center" />
            <div className="row g-4 d-none d-md-flex">
              {feedbackList.map((v) => {
                const { id } = v;
                return <Item key={id} data={v} />;
              })}
            </div>
            <MobileFeedbackList></MobileFeedbackList>
          </div>
        </div>
      </section>
    </>
  );
}

export default Content;
