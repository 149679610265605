import React from 'react';

import KeyVisual from 'components/WinterCamp/KeyVisual';
import Pictures from 'components/WinterCamp/Pictures';
import Footer from 'components/WinterCamp/Footer';
import { CourseCard } from 'components/WinterCamp/CourseCard';
import { Schedule } from 'components/WinterCamp/Schedule';

const WinterCamp = () => {
  const LAYOUT_CLASS = 'l-winter-camp';
  return (
    <>
      <div className="l-winter-camp">
        <KeyVisual LAYOUT_CLASS={LAYOUT_CLASS} />
        <CourseCard LAYOUT_CLASS={LAYOUT_CLASS} />
        <Schedule LAYOUT_CLASS={LAYOUT_CLASS} />
        <Pictures LAYOUT_CLASS={LAYOUT_CLASS} />
        <Footer LAYOUT_CLASS={LAYOUT_CLASS} />
      </div>
    </>
  );
};

export default WinterCamp;
