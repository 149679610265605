import React from 'react';
import Title from '../Title';
import { qaList } from 'data/summer-camp';
import Item from './Item';

const Content = ({ LAYOUT_CLASS }) => {
  return (
    <>
      <section className={`${LAYOUT_CLASS}__section u-section`}>
        <Title text="問答Q&A" deco="QUESTION" decoStyle="1" />
        <div className="vstack gap-3">
          {qaList.map((v) => {
            return <Item key={v.id} LAYOUT_CLASS={LAYOUT_CLASS} data={v} />;
          })}
        </div>
      </section>
    </>
  );
};

export default Content;
