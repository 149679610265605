// 競賽培訓
export const competitionList = [
  {
    id: 'APCS',
    title: 'APCS大學程式設計先修檢測',
    content:
      'APCS 是指「大學程式設計先修檢測」，是目前衡量國高中生程式能力最有公信力的一個指標。目前共有 47 所校系，在個人申請中設立 APCS 組，總計開放 118 個名額，提供具有程式能力的學生額外的入學管道。',
    time: '1月，6月，10月檢定',
    target: '任何人皆可參加',
    website: 'https://apcs.csie.ntnu.edu.tw/',
  },
  {
    id: 'TOI',
    title: 'TOI國際資訊奧林匹亞競賽',
    content:
      'TOI 國際資訊奧林匹亞競賽是一個國際性的資訊科學競賽，目的是鼓勵和表彰在計算機科學和資訊技術方面具有卓越才能的學生。該競賽包括一系列的編程挑戰和解決問題的任務，是全球頂尖的資訊科學競賽之一。',
    time: '1月海選，3月初選，3/4/5/10/11/12月 線上賽',
    target: '國高中職在校生皆可參加',
    website: 'https://tpmso.org/toi/',
  },
  {
    id: 'ISSC',
    title: 'ISSC青年程式競賽',
    content:
      '中華民國電腦學會為提升我國高中及高職資訊教育品質，和青年學生之程式設計能力為主要目標，並以程式設計選拔優秀高中與高職青年學生，代表我國參加每年國際ISSC程式設計競賽',
    time: '11/12月報名，12月比賽',
    target: '高中職在校生皆可參加',
    website: 'https://issc.csroc.org.tw/',
  },
  {
    id: 'YTP',
    title: 'YTP少年圖靈計畫',
    content:
      'YTP少年圖靈計畫是一個旨在推廣計算機科學及程式設計教育的非營利組織，透過程式競賽、專題實做、大學教授指導、企業志工導師輔導、海外參訪學習，啟發台灣學生對程式設計的興趣及熱誠，引導學生運用軟體力解決社會問題，或改善現況',
    time: '3月－6月報名，7月比賽',
    target: '國高中職在校生皆可參加',
    website: 'https://www.tw-ytp.org/',
  },
  {
    id: 'HSPC',
    title: 'HSPC成大暑期高中生程式設計邀請賽',
    content:
      '集結各縣市高中職之學生程式競賽好手組隊互相切磋，介紹成功大學資訊工程學系、特殊入學管道及其相關資源，給對程式設計有興趣的學生',
    time: '3月－5月報名，6月校內初賽',
    target: '高中職在校生皆可參加',
    website: '',
  },
  {
    id: 'HPE',
    title: 'HPE CodeWars',
    content:
      'HPE CodeWars是一個程式設計比賽，由惠普企業贊助，旨在挑戰參賽者的程式設計和問題解決能力。該比賽主要針對學生和大學生，提供實戰經驗和與業界領袖互動的機會。',
    time: '3月－5月報名，6月校內初賽',
    target: '高中職在校生皆可參加',
    website: '',
  },
  {
    id: 'HS',
    title: '普通型高級中等學校數理及資訊學科能力競賽',
    content:
      '資訊學科能力競賽是台灣的國家級競賽，旨在推廣資訊科技、培育資訊人才，提高學生的計算機科學能力。考試項目包括程式設計、演算法、資料庫、網路應用等。部分學校特殊選才看重此競賽成績。',
    time: '10月校內初賽，11月複賽，12月決賽',
    target: '高中職在校生皆可參加',
    website:
      'https://www.edu.tw/News_Content.aspx?n=9E7AC85F1954DDA8&s=B609FD015C004831',
  },
  {
    id: 'APIO',
    title: 'APIO亞太資訊奧林匹亞',
    content:
      'APIO 亞太資訊奧林匹亞是一個亞太地區的資訊科學競賽，旨在提高學生在計算機科學和資訊技術方面的解決問題能力和創造力。該競賽包括一系列的編程和算法設計挑戰，是該地區頂尖的資訊科學競賽之一。',
    time: '3月－5月報名，6月校內初賽',
    target: '高中職在校生皆可參加',
    website: '',
  },
  {
    id: 'NPSC',
    title: 'NPSC網際網路程式設計全國大賽',
    content:
      'NPSC網際網路程式設計全國大賽是台灣最具代表性的網路程式設計競賽，由台灣大學主辦，旨在提升學生的程式設計技能和解決問題的能力。',
    time: '10月報名，11月初賽，12月決賽',
    target: '國高中職在校生皆可參加',
    website: 'https://contest.cc.ntu.edu.tw/npsc2022/',
  },
  {
    id: 'IOI',
    title: 'IOI程式解題競賽集訓營',
    content:
      '在各種程式設計競賽當中，便有一類比賽，特別考驗選手們在短時間內進行思考、並且實際撰寫出能有效率處理資料並解決問題的程式，透過競賽類型題目奠基設計良好程式的基礎。',
    time: '2月冬令營',
    target: '國高中職在校生皆可參加',
    website: 'https://ioicamp.org/',
  },
];
export const monthList = [
  {
    id: 1,
    month: 1,
    list: [
      {
        competitionId: 'APCS',
        isClass: true,
      },
      { competitionId: 'TOI', isClass: false },
    ],
  },
  {
    id: 2,
    month: 2,
    list: [
      {
        competitionId: 'IOI',
        isClass: false,
      },
    ],
  },
  {
    id: 3,
    month: 3,
    list: [
      {
        competitionId: 'TOI',
        isClass: false,
      },
    ],
  },
  {
    id: 3,
    month: 6,
    list: [
      {
        competitionId: 'APCS',
        isClass: true,
      },
    ],
  },
  {
    id: 4,
    month: 7,
    list: [
      {
        competitionId: 'YTP',
        isClass: false,
      },
      // {
      //   competitionId: 'HSPC',
      //   isClass: false,
      // },
      // {
      //   competitionId: 'HPE',
      //   isClass: false,
      // },
      // {
      //   competitionId: 'HS',
      //   isClass: false,
      // },
    ],
  },
  {
    id: 5,
    month: 10,
    list: [
      {
        competitionId: 'APCS',
        isClass: true,
      },
      {
        competitionId: 'HS',
        isClass: false,
      },
    ],
  },
  {
    id: 6,
    month: 11,
    list: [
      {
        competitionId: 'NPSC',
        isClass: false,
      },
    ],
  },
  {
    id: 7,
    month: 12,
    list: [
      {
        competitionId: 'ISSC',
        isClass: false,
      },
    ],
  },
];
