import React, { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';

import { useCourseData } from 'utils/context';

function Nav(props) {
  const { nav, setNav } = props;
  const [offsetY, setOffsetY] = useState(0);
  const navList = [
    { id: 1, title: '課程簡介', link: '#intro' },
    { id: 2, title: '開課資訊', link: '#info' },
    { id: 3, title: '課程大綱', link: '#outline' },
    { id: 4, title: '更多課程', link: '#more' },
  ];
  const handleScroll = () => setOffsetY(window.pageYOffset);
  const isShadowNav = offsetY >= 176;

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <>
      <div className={`c-course-nav ${isShadowNav ? 'active' : ''}`}>
        <ul className="c-course-nav__list d-flex">
          {navList.map((item) => {
            const { id, title, link } = item;
            return (
              <li
                key={id}
                className={`c-course-nav__item  ${nav === id ? 'active' : ''}`}
              >
                <HashLink
                  to={link}
                  smooth
                  className="c-course-nav__link"
                  onClick={() => setNav(id)}
                >
                  {title}
                </HashLink>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

export default Nav;
