import React from 'react';

import Heading from 'components/Home/Feedbacks/Heading';

import useWindowDimensions from 'hooks/useWindowDimensions';

const Item = (props) => {
  const { data } = props;
  const { id, content } = data;

  const { height, width } = useWindowDimensions();

  const handleAOS = (id) => {
    if (width > 992) {
      switch (id) {
        case '2':
          return 'fade-left';
        case '3':
          return 'fade-right';
        case '6':
          return 'fade-left';
        default:
          return;
      }
    }
  };

  return (
    <>
      <div className="col-12 col-md-6 d-flex align-items-stretch">
        <div className="l-feedback__card" data-aos={handleAOS(id)}>
          <div className="l-feedback__content">
            <div className="d-flex flex-column flex-sm-row align-items-center mb-3">
              <Heading data={data} />
            </div>
            <div className="l-feedback__text">{content}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Item;
