import Title from 'components/Title';
import React from 'react';
import { Link } from 'react-router-dom';
import IconPlaceholder from '../IconPlaceholder';

const Content = ({ PAGE_CLASS }) => {
  const COMPONENT_CLASS = 'feature';

  const featureList = [
    {
      id: 1,
      title: '我是一個特色敘述',
      icon: <IconPlaceholder />,
    },
    {
      id: 2,
      title: '我是一個數字統計',
      num: '100%',
    },
    {
      id: 3,
      title: '我是一個特色敘述',
      icon: <IconPlaceholder />,
    },
    {
      id: 4,
      title: '我是一個數字統計',
      num: '100%',
    },
  ];
  return (
    <>
      <div
        className={`${PAGE_CLASS}__${COMPONENT_CLASS} ${PAGE_CLASS}__section`}
        data-section="2"
      >
        <div className="row justify-content-center g-0">
          <div className="col-8">
            <div className="vstack gap-3 text-center">
              <Title text="我們的特色" align="center" />
              <div className="row">
                {featureList.map((o) => (
                  <div key={o.id} className="col-3">
                    <div
                      className={`${PAGE_CLASS}__${COMPONENT_CLASS}__item vstack gap-3`}
                    >
                      <div
                        className={`${PAGE_CLASS}__${COMPONENT_CLASS}__icon`}
                      >
                        {o.icon ? o.icon : <>{o.num}</>}
                      </div>
                      <div className={`${PAGE_CLASS}__text`}>{o.title}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
