import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import Title from 'components/Title';

import studentImg1 from 'img/student-feedback/avatar__Hung.jpg';
import Item from './Item';

import 'swiper/css';
import 'swiper/css/pagination';

const Content = ({ PAGE_CLASS }) => {
  const COMPONENT_CLASS = 'admission-list';

  const breakpoints = {
    0: {
      slidesPerView: 1,
      slidesPerGroup: 1,
    },
    992: {
      slidesPerView: 2,
      slidesPerGroup: 2,
    },
  };

  const studentList = [
    {
      id: 1,
      name: '學生姓名',
      title: '學生學校名稱',
      list: ['UIUC', 'Purdue', 'Washigton', 'Northeastern'],
      img: studentImg1,
    },
    {
      id: 2,
      name: '學生姓名',
      title: '學生學校名稱',
      list: ['UIUC', 'Purdue', 'Washigton', 'Northeastern'],
      img: studentImg1,
    },
    {
      id: 3,
      name: '學生姓名',
      title: '學生學校名稱',
      list: ['UIUC', 'Purdue', 'Washigton', 'Northeastern'],
      img: studentImg1,
    },
    {
      id: 4,
      name: '學生姓名',
      title: '學生學校名稱',
      list: ['UIUC', 'Purdue', 'Washigton', 'Northeastern'],
      img: studentImg1,
    },
    {
      id: 5,
      name: '學生姓名',
      title: '學生學校名稱',
      list: ['UIUC', 'Purdue', 'Washigton', 'Northeastern'],
      img: studentImg1,
    },
    {
      id: 6,
      name: '學生姓名',
      title: '學生學校名稱',
      list: ['UIUC', 'Purdue', 'Washigton', 'Northeastern'],
      img: studentImg1,
    },
    {
      id: 7,
      name: '學生姓名',
      title: '學生學校名稱',
      list: ['UIUC', 'Purdue', 'Washigton', 'Northeastern'],
      img: studentImg1,
    },
    {
      id: 8,
      name: '學生姓名',
      title: '學生學校名稱',
      list: ['UIUC', 'Purdue', 'Washigton', 'Northeastern'],
      img: studentImg1,
    },
    {
      id: 9,
      name: '學生姓名',
      title: '學生學校名稱',
      list: ['UIUC', 'Purdue', 'Washigton', 'Northeastern'],
      img: studentImg1,
    },
    {
      id: 10,
      name: '學生姓名',
      title: '學生學校名稱',
      list: ['UIUC', 'Purdue', 'Washigton', 'Northeastern'],
      img: studentImg1,
    },
  ];

  return (
    <>
      <div
        className={`${PAGE_CLASS}__${COMPONENT_CLASS} ${PAGE_CLASS}__section`}
        data-section="4"
      >
        <div className="row justify-content-center g-0 ">
          <div className="col-10 col-xxl-8 ">
            <Title text="優秀學員榜單" align="center" />
            <div
              className={`${PAGE_CLASS}__${COMPONENT_CLASS}__swiper__wrapper position-relative`}
            >
              <Swiper
                breakpoints={breakpoints}
                speed={1000}
                loop={true}
                autoplay={{
                  delay: 3500,
                  disableOnInteraction: false,
                }}
                navigation={{
                  prevEl: `.${PAGE_CLASS}__${COMPONENT_CLASS}__swiper__action.prev`,
                  nextEl: `.${PAGE_CLASS}__${COMPONENT_CLASS}__swiper__action.next`,
                }}
                pagination={{ clickable: true }}
                modules={[Autoplay, Navigation, Pagination]}
                className={`${PAGE_CLASS}__${COMPONENT_CLASS}__swiper position-relative`}
              >
                {studentList.map((o) => (
                  <SwiperSlide
                    key={o.id}
                    className={`${PAGE_CLASS}__${COMPONENT_CLASS}__swiper__slide`}
                  >
                    <Item
                      key={o.id}
                      data={o}
                      PAGE_CLASS={PAGE_CLASS}
                      COMPONENT_CLASS={COMPONENT_CLASS}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              <button
                className={`${PAGE_CLASS}__${COMPONENT_CLASS}__swiper__action prev`}
              >
                <FontAwesomeIcon icon={solid('angle-left')} className="fa-fw" />
              </button>
              <button
                className={`${PAGE_CLASS}__${COMPONENT_CLASS}__swiper__action next`}
              >
                <FontAwesomeIcon
                  icon={solid('angle-right')}
                  className="fa-fw"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
