import React from 'react';

import Title from 'components/Title';
import CourseItem from 'components/CourseItem';

function CourseList(props) {
  const { title, data } = props;
  return (
    <>
      <section className="l-home__course u-home-section">
        <div className="row gx-0 justify-content-center w-100">
          <div className="col-10">
            <Title text={title} align="center" />
            <div className="row g-4">
              {data.map((v) => {
                const { id } = v;
                return <CourseItem key={id} data={v} col="4" />;
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CourseList;
